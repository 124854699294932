import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  Typography,
  Tabs,
  Tab,
  Tooltip,
  IconButton,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Box,
  useTheme,
  useMediaQuery,
  Menu,
  MenuItem,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { NavLink } from "react-router-dom";
import LoginPopover from "../auth/Login";

const CustomAppBar = ({
  selectedOption,
  setSelectedOption,
  navigateToDashboard,
  navigateHome,
  handleChange,
  expertSignUp,
}) => {
  const theme = useTheme();
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const menuItems = [
    {
      text: "Book A Franchisee",
      path: "/book-franchisee",
      name: "bookFranchisee",
    },
    {
      text: "All Franchises",
      path: "/all-franchises",
      name: "franchiseListings",
    },
    {
      text: "Blog",
      path: "/blog",
      name: "blog",
    },
    {
      text: "Insights",
      path: "/insight",
      name: "insight",
      // submenu: [
      //   { text: "Insight 1", path: "/insight/insight-1" },
      //   { text: "Insight 2", path: "/insight/insight-2" },
      // ],
    },
    {
      text: "Contact Us",
      path: "/site-suggestions",
      name: "feedback",
    },
  ];

  const list = () => (
    <Box
      sx={{
        width: 250,
        backgroundColor: theme.palette.primary.main,
        color: "white",
      }}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        {menuItems.map((item) => (
          <React.Fragment key={item.text}>
            {item.submenu ? (
              <>
                <ListItem>
                  <ListItemText primary={item.text} />
                </ListItem>
                {item.submenu.map((subItem) => (
                  <ListItem
                    key={subItem.text}
                    component={NavLink}
                    to={subItem.path}
                    onClick={() => setSelectedOption(subItem.text)}
                    sx={{
                      color: "white",
                      fontWeight:
                        selectedOption === subItem.text ? "bold" : "normal",
                      pl: 4, // Indentation for submenu items
                    }}
                  >
                    <ListItemText primary={subItem.text} />
                  </ListItem>
                ))}
              </>
            ) : (
              <ListItem
                component={NavLink}
                to={item.path}
                onClick={() => setSelectedOption(item.name)}
                sx={{
                  color: "white",
                  fontWeight: selectedOption === item.name ? "bold" : "normal",
                }}
              >
                <ListItemText
                  primary={item.text}
                  sx={{
                    color: "white",
                    fontWeight:
                      selectedOption === item.name ? "bold" : "normal",
                  }}
                />
              </ListItem>
            )}
          </React.Fragment>
        ))}
      </List>
    </Box>
  );

  return (
    <AppBar
      position="static"
      sx={{
        backgroundColor: "#0098dc",
        boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          boxShadow: "2px 2px 5px rgba(0, 0, 0, 0.35)",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={toggleDrawer(true)}
            sx={{ mr: 2, display: { md: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            sx={{ color: "white", cursor: "pointer" }}
            onClick={navigateHome}
          >
            FranchiseNow
          </Typography>
          {!isMobile ? (
            <Typography
              variant="subtitle1"
              sx={{
                color: "white",
                marginLeft: 3.5,
                fontStyle: "italic",
              }}
            >
              One stop shop for over +1,400 franchises
            </Typography>
          ) : null}
        </Box>
        {!isMobile ? (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Tabs
              value={selectedOption}
              onChange={handleChange}
              sx={{
                ".MuiTabs-indicator": { backgroundColor: "white" },
                marginTop: 1.5,
                marginRight: 10,
              }}
              textColor="inherit"
              indicatorColor="secondary"
              className="tabs"
            >
              {menuItems.map((tab) => (
                <React.Fragment key={tab.text}>
                  {tab.submenu ? (
                    <Tab
                      label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                          <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Tooltip
                              title={`Explore ${tab.text}`}
                              enterDelay={500}
                              leaveDelay={200}
                            >
                              <span>{tab.text}</span>
                            </Tooltip>
                            <ExpandMoreIcon sx={{ ml: 1 }} />{" "}
                          </Box>
                        </Box>
                      }
                      className="insights-tab"
                      onClick={handleMenuClick}
                    />
                  ) : (
                    <Tab
                      label={
                        <Tooltip
                          title={tab.text}
                          enterDelay={500}
                          leaveDelay={200}
                        >
                          <span>{tab.text}</span>
                        </Tooltip>
                      }
                      value={tab.name}
                      className={tab.name + "-tab"}
                      component={NavLink}
                      to={tab.path}
                    />
                  )}
                </React.Fragment>
              ))}
            </Tabs>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              {menuItems
                .find((item) => item.submenu)
                ?.submenu.map((subItem) => (
                  <MenuItem
                    key={subItem.text}
                    component={NavLink}
                    to={subItem.path}
                    onClick={() => {
                      handleMenuClose();
                      setSelectedOption(subItem.text);
                    }}
                  >
                    {subItem.text}
                  </MenuItem>
                ))}
            </Menu>
          </Box>
        ) : null}
        {!isMobile ? (
          <Typography
            variant="subtitle1"
            sx={{
              color: "#ADD8E6",
              textAlign: "left",
              fontStyle: "italic",
            }}
            onClick={expertSignUp}
          >
            Current or Former Franchisee? <br />
            <div
              style={{
                textDecoration: "underline",
                textAlign: "center",
                color: "white",
              }}
            >
              Join our Expert Network
            </div>
          </Typography>
        ) : null}
        <LoginPopover
          navigateToDashboard={navigateToDashboard}
          navigateHome={navigateHome}
        />
      </Toolbar>
      <Drawer anchor="left" open={isDrawerOpen} onClose={toggleDrawer(false)}>
        {list()}
      </Drawer>
    </AppBar>
  );
};

export default CustomAppBar;

import React, { useState, useEffect } from "react";
import { Card, Typography, Button, Grid, Box } from "@mui/material";
import { loadStripe } from "@stripe/stripe-js";
import { useAuth } from "../auth/Auth";
import { useNavigate } from "react-router-dom";
import { useToast } from "./useToast";
import LoginPopup from "../auth/LoginPopup";
import { getDatabase, ref, onValue, set } from "firebase/database";

// Initialize Stripe with your publishable key
const stripePromise = loadStripe(
  "pk_live_51MK83mI0sGpaLBF2hF85MwnBdE7EmnGYptNM2BvGELz2iNblDJuljiov0D0B1QF0u6IWUia8yeQN136UvZsA2CAZ00Lt8hHfQU"
);

const SpotlightCard = ({ data }) => {
  const [showMoreState, setShowMoreState] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const auth = useAuth();
  const navigate = useNavigate();
  const { showToast, ToastComponent } = useToast();
  const [loginPopupOpen, setLoginPopupOpen] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(false);

  const db = getDatabase();
  const truncateLimit = 3000; // Set your truncation limit

  // Fetch subscription status on load
  useEffect(() => {
    if (auth.currentUser) {
      const userRef = ref(
        db,
        `users/${auth.currentUser.uid}/subscriptionStatus`
      );
      onValue(userRef, (snapshot) => {
        const data = snapshot.val();
        setIsSubscribed(data === true);
      });
    }
  }, [auth.currentUser, db]);

  // Handle Stripe Checkout for subscription
  const handleCheckout = async () => {
    if (!auth.isAuthenticated) {
      showToast("You must be logged in to subscribe.");
      setLoginPopupOpen(true);
      return;
    }
    console.log("User is authenticated, proceeding with checkout");
    try {
      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        lineItems: [{ price: "price_1PpiIGI0sGpaLBF2gOYSyqCm", quantity: 1 }],
        mode: "subscription",
        successUrl: `${window.location.origin}/success`,
        cancelUrl: `${window.location.origin}/`,
      });

      if (!error) {
        console.log("Stripe checkout successful, updating subscription...");
        // Update subscription status in Realtime Database when user successfully subscribes
        const userRef = ref(db, `users/${auth.currentUser.uid}`);
        set(userRef, { subscriptionStatus: true })
          .then(() => {
            console.log("subscription updated successfully");
          })
          .catch((error) => {
            console.error("Error updating users: ", error);
          });

        // Update local subscription state
        setIsSubscribed(true);
        auth.updateSubscriptionStatus(true);
      } else {
        console.error("Error during checkout: ", error);
      }
    } catch (error) {
      console.error("Error initializing Stripe: ", error);
    }
  };

  // Toggle "show more" state for spotlight cards
  const handleShowMoreToggle = (id) => {
    setShowMoreState((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  // Truncate HTML content for description
  const getTextContentLength = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    return doc.body.textContent.length;
  };

  const truncateHTML = (htmlString, limit) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, "text/html");
    let charCount = 0;
    let truncated = false;

    const walk = (node) => {
      let result = "";

      if (node.nodeType === Node.TEXT_NODE) {
        let textContent = node.textContent;
        const remaining = limit - charCount;

        if (charCount + textContent.length > limit) {
          const truncatedText = textContent.substring(0, remaining);
          const lastSpaceIndex = truncatedText.lastIndexOf(" ");
          result = truncatedText.substring(
            0,
            lastSpaceIndex > -1 ? lastSpaceIndex : remaining
          );
          charCount += result.length;
          truncated = true;
        } else {
          result = textContent;
          charCount += textContent.length;
        }
      } else if (node.nodeType === Node.ELEMENT_NODE && !truncated) {
        const nodeName = node.nodeName.toLowerCase();
        result += `<${nodeName}`;

        for (const attr of node.attributes) {
          result += ` ${attr.name}="${attr.value}"`;
        }

        result += ">";

        for (const child of node.childNodes) {
          result += walk(child);
          if (truncated) break;
        }

        result += `</${nodeName}>`;
      }

      return result;
    };

    const truncatedHTML = walk(doc.body);

    if (truncated) {
      return (
        truncatedHTML.trim() +
        `<div style="text-align: center; font-size: 2.5rem; margin: 0.1rem 0;">...</div>`
      );
    }

    return truncatedHTML;
  };

  // Show modal after scrolling down a certain percentage of the page
  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const scrollPercentage =
        (scrollTop / (documentHeight - windowHeight)) * 80;

      if (scrollPercentage > 40 && !isSubscribed) {
        setIsModalVisible(true);
      } else {
        setIsModalVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isSubscribed]);

  const displayedCards = data.cardData.slice(0, data.cardData.length);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "#00A6D6",
          padding: { xs: 2, sm: 4, md: 6 },
          maxWidth: { xs: "100%", sm: "1200px", md: "1700px" },
          margin: "0 auto",
          position: "relative",
        }}
      >
        <Typography
          variant="h4"
          mb={2}
          fontWeight="bold"
          style={{ color: "#FFFFFF" }}
        >
          Franchisee Spotlights
        </Typography>
        {displayedCards.map((card, index) => {
          const { id, title, description, points, image } = card;
          const showMore = showMoreState[id] || false;

          // Get the length of the text content (excluding HTML tags)
          const pointsTextLength = getTextContentLength(points);

          // Check if truncation is needed
          const shouldShowMoreLess = pointsTextLength > truncateLimit;

          return (
            <Card
              key={id}
              className={index >= 2 && !isSubscribed ? "blurred-card" : ""}
              style={{
                backgroundColor: "#EBF8FB",
                borderBottomLeftRadius: 10,
                borderBottomRightRadius: 10,
                marginBottom: 20,
                position: "relative",
                overflow: "hidden",
                cursor: "pointer",
                filter: index >= 2 && !isSubscribed ? "blur(8px)" : "none", // Apply blur only if not subscribed
              }}
            >
              <Grid container>
                <Grid item xs={12} md={9} style={{ padding: "20px" }}>
                  <Typography
                    variant="h5"
                    style={{
                      fontWeight: 600,
                      marginBottom: 15,
                      color: "#2A3042",
                    }}
                  >
                    {title}
                  </Typography>
                  <Typography
                    variant="body2"
                    style={{
                      color: "#6c757d",
                      marginBottom: 15,
                      fontStyle: "italic",
                    }}
                    dangerouslySetInnerHTML={{ __html: description }}
                  />
                  <Box
                    component="hr"
                    style={{
                      border: "none",
                      borderBottom: "1px solid #d1d1d1",
                      marginBottom: 15,
                    }}
                  />
                  <div
                    dangerouslySetInnerHTML={{
                      __html: showMore
                        ? points
                        : truncateHTML(points, truncateLimit),
                    }}
                    style={{
                      marginBottom: 10,
                      color: "#2A3042",
                      fontSize: "14px",
                    }}
                    onClick={() => handleShowMoreToggle(id)}
                  />
                  {/* Conditionally render the button based on the text length */}
                  {shouldShowMoreLess && (
                    <Button
                      onClick={() => handleShowMoreToggle(id)}
                      style={{
                        marginTop: 15,
                        backgroundColor: "#EBF8FB",
                        color: "#2A3042",
                        textTransform: "none",
                        border: "1px solid #d1d1d1",
                        padding: "5px 15px",
                        borderRadius: 20,
                      }}
                    >
                      {showMore ? "See less" : "See more"}
                    </Button>
                  )}
                </Grid>
                <Grid item xs={12} md={3}>
                  <img
                    src={image}
                    alt="Spotlight"
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Grid>
              </Grid>
            </Card>
          );
        })}

        {isModalVisible && (
          <Box
            sx={{
              position: "fixed",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              backgroundColor: "#FFFFFF",
              padding: "20px",
              borderRadius: "15px",
              boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.25)",
              textAlign: "center",
              zIndex: 2,
              width: "80%",
              maxWidth: "500px",
            }}
          >
            <Typography
              variant="h6"
              style={{
                fontWeight: 600,
                marginBottom: 15,
                color: "#2A3042",
              }}
              sx={{ mb: 2 }}
            >
              Subscribe to See all FranchiseNow Spotlights
            </Typography>
            <Typography variant="body1" sx={{ mb: 4 }}>
              Get unlimited access to every spotlight and interview for all
              companies in the FranchiseNow library
            </Typography>
            <Button
              onClick={handleCheckout}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: "#00A6D6",
                color: "#FFFFFF",
                borderRadius: "20px",
                textTransform: "none",
                padding: "8px 16px",
                width: "100%",
              }}
            >
              Subscribe
            </Button>
          </Box>
        )}
      </Box>
      <LoginPopup
        open={loginPopupOpen}
        onClose={() => setLoginPopupOpen(false)}
      />
      <ToastComponent />
    </>
  );
};

export default SpotlightCard;

// src/components/InsightList.js
import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Button,
  Box,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { wingStopData } from "../utils/insightData"; // Adjust the path according to your file structure

const InsightList = () => {
  const navigate = useNavigate();

  const handleRowClick = (slug) => {
    navigate(`/insight/${slug}`);
  };

  return (
    <TableContainer sx={{ margin: "0 auto", marginTop: 10, maxWidth: 1200 }}>
      <Table>
        <TableBody>
          {wingStopData.map((item) => (
            <TableRow
              key={item.slug}
              sx={{
                cursor: "pointer",
                "&:hover": {
                  backgroundColor: "action.hover",
                },
              }}
              onClick={() => handleRowClick(item.slug)}
            >
              <TableCell>
                <Box
                  component="img"
                  src={item.pageData.pic}
                  alt={item.pageData.title}
                  sx={{
                    width: 50,
                    height: 50,
                    objectFit: "cover",
                    borderRadius: "50%",
                  }}
                />
              </TableCell>
              <TableCell>
                <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                  {item.pageData.title}
                </Typography>
              </TableCell>
              <TableCell>
                <Typography variant="body2" color="text.secondary">
                  {`${item.pageData.description.slice(0, 100)}...`}
                </Typography>
              </TableCell>
              <TableCell>
                <Button
                  variant="contained"
                  onClick={(e) => {
                    e.stopPropagation(); // Prevent click from bubbling up to row
                    handleRowClick(item.slug);
                  }}
                  sx={{
                    backgroundColor: "primary.main",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "primary.dark",
                    },
                  }}
                >
                  Insights
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default InsightList;

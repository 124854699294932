// useToast.js
import { useState } from "react";
import { Snackbar, Alert } from "@mui/material";

export const useToast = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");

  const showToast = (msg) => {
    setMessage(msg);
    setOpen(true);
    setTimeout(() => setOpen(false), 3000); // Auto-hide after 3 seconds
  };

  const ToastComponent = () => (
    <Snackbar
      open={open}
      autoHideDuration={3000}
      onClose={() => setOpen(false)}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      <Alert onClose={() => setOpen(false)} severity="info">
        {message}
      </Alert>
    </Snackbar>
  );

  return { showToast, ToastComponent };
};

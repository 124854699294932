import React, { useEffect } from "react";
import { Container, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useAuth } from "../auth/Auth"; // Import your authentication hook
import { getDatabase, ref, set } from "firebase/database";
import { useNavigate } from "react-router-dom";
const postURL =
  "https://us-central1-franchise-5bd11.cloudfunctions.net/app/send-email";
const contactEmail = "contact@FranchiseNow.me";
const SuccessPage = () => {
  const auth = useAuth();
  const db = getDatabase();
  const navigate = useNavigate();

  useEffect(() => {
    // Check if the user is authenticated
    if (auth.currentUser) {
      // Update subscription status in Realtime Database
      const userRef = ref(db, `users/${auth.currentUser.uid}`);
      set(userRef, { subscriptionStatus: true })
        .then(() => {
          console.log("Subscription status updated successfully.");
          // Optionally, navigate to another page after updating
          const emailData = {
            to: [contactEmail],
            subject: "FranchiseNow Subscription",
            text: `Hi,\n\nA FranchiseNow client has subscribed.`,
          };
          fetch(postURL, {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(emailData),
          });
          navigate("/success"); // Redirect to a relevant page
        })
        .catch((error) => {
          console.error("Error updating subscription status:", error);
        });
    }
  }, [auth, db, navigate]);

  return (
    <Container maxWidth="sm" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Payment Successful!
      </Typography>
      <Typography variant="body1" align="center" gutterBottom>
        Thank you for your purchase.
      </Typography>
      <Button
        component={Link}
        to="/insight"
        variant="contained"
        color="primary"
        fullWidth
        sx={{ mt: 2 }}
      >
        Back to Insights
      </Button>
    </Container>
  );
};

export default SuccessPage;

export const wingStopData = [
  {
    slug: "McDonald's",
    pageData: {
      pic: "/logos/McDonald's.jpg",
      title: "McDonald's",
      initialInvestment: "$1.5M-$2.5M",
      franchiseeFee: "$45k",
      royaltyRate: "5.0%",
      description:
        "McDonald's Corporation is a global fast-food chain and one of the world's largest restaurant companies, known for its iconic golden arches logo. Founded in 1940, McDonald's serves a variety of quick-service meals, including its signature hamburgers, fries, chicken products, breakfast items, and beverages. The company operates in over 100 countries with thousands of locations, primarily through a franchising model, making it a familiar and trusted brand for millions of customers worldwide. McDonald's is committed to delivering consistent, high-quality food and customer service, with a focus on convenience, affordability, and innovation in its offerings.",
    },
    keyTrends: [
      {
        leftDescription:
          "The new <strong>$5 value meal in 2024</strong> seems to be succeeding in bolstering traffic which has led to the vast majority of franchisees commit to extending the promotion",
        rightDescription:
          "McDonald’s franchisees <strong>cannot own the underlying real estate</strong> (unlike Burger King franchisees) and must be mindful of rent raises if the landlord is not Corporate",
      },
      {
        leftDescription:
          "Several <strong>tech-enabled efficiency initiatives</strong> (e.g., system-wide digital app, Service Area Optimization) have allowed McDonald’s to maintain its <strong>QSR leadership position</strong>",
        rightDescription:
          "Impact of <strong>weight-loss GLP-1 drugs</strong> has not been felt by franchisees yet but it is still early in the adoption cycle and the jury is still out on the behavioral evolution of users",
      },
      {
        leftDescription:
          "New <strong>smaller McDonald’s restaurant prototypes</strong> are lauded as higher ROI with <strong>shorter payback periods</strong> especially given the rise of online orders and 3rd party delivery services",
        rightDescription:
          "<strong>Franchisee buy-in</strong> is not always guaranteed when Corporate wants to introduce menu innovations (e.g., plant-based foods, more involved coffee items) as they may add operational complexity and can <strong>impair guest throughput</strong> (important for margins)",
      },
    ],
    cardData: [
      {
        id: 1,
        title: "Spotlight #1 – McDonald's Franchise Owner – October 2023",
        description:
          "Addresses a range of topics including the impact of minimum wage increases on pricing and sales, pros and cons of the new $5 value meal, structure of franchise agreements, rent payments, margins, technology implementation, and the important of adapting to changing market conditions",
        points: [
          `
<ul>
  <li>The interviewee owns <strong>6 McDonald’s restaurants in Southern California</strong> and has been a franchisee for <strong>35 years</strong>; CA is a high-cost market where the <strong>minimum wage is +$20/hour</strong> (25% increase in labor costs overnight as of April once the Governor of California signed this developed into law earlier this year)
    <ul>
      <li>Franchisors that have <strong>at least 60 sites</strong> are bound by this law and need to comply – which leaves smaller regional concepts with a <strong>labor cost advantage</strong></li>
      <li>There has been <strong>no direct financial assistance</strong> from corporate in response to wage increases and thus operators must focus on efficiency and productivity to manage increased costs</li>
    </ul>
  </li>
  <li>Sales and guest counts have decreased as of late due to price increases and <strong>negative media narratives</strong> about fast food prices; franchisees have the <strong>autonomy to set prices</strong> but generally participate in local and national promotions funded by their sales contributions to advertising co-ops (participation in promotions is crucial for maintaining sales volumes)
    <ul>
      <li>Media portrayals of price taking are overblown as McDonald’s restaurants have on average only raised the price of a combo meal by <strong>~$0.20-0.30</strong> which is not exorbitant</li>
      <li><strong>Convenience stores</strong> have been taking some market share, especially the ones that have not raised prices – unclear how sustainable this dynamic is however as customers aren’t really getting access to fresh food at convenience stores</li>
      <li>McDonald’s is well-positioned to weather these temporary headwinds due to the support from corporate and their <strong>R&D</strong> leading to technology driven improvements</li>
    </ul>
  </li>
  <li>There is no doubt that <strong>McDonald’s remains a leader</strong> in the QSR sector but competitive dynamics will vary geographically – for example <strong>In-N-Out Burger</strong> is a significant competitor in the Western US while in some markets <strong>Tim Hortons</strong> (which has maintained value pricing) is benefiting from current economic conditions and macro softness
    <ul>
      <li>The interviewee believes that McDonald’s is well-positioned at the end of the day to weather economic storms and <strong>maintain market leadership</strong></li>
    </ul>
  </li>
  <li>Corporate is trying to stimulate traffic through the roll-out and extension of value promotions (e.g., <strong>$5 value meal</strong>) but some franchisees have been resisting these initiatives
    <ul>
      <li><strong>30-40% of franchisees</strong> do not want to participate in value promotions and thus there is some strain building on the relationships between corporate and its franchisees</li>
      <li>The original promo was <strong>4-weeks long</strong> which could throw in a <strong>4-piece McNuggets box</strong> with a combo order (McDouble cheeseburger or McChicken sandwich with a small fries and small drink) in order to jump-start routine combo meal spending again – Corporate has now decided to extend the timeline of this deal with <strong>+90%</strong> of restaurants committing to extending the deal even further into the summer</li>
      <li>The <strong>$5 value combo meal</strong> itself has about a <strong>1-5% profit margin</strong> relative to the <strong>5-10% profit margin</strong> on a traditional McDonald’s meal – but the deal has the added benefit of getting price-sensitive customers back in the door with the possibility that they will spend beyond the value meal once they are inside</li>
      <li>Sandwiches (chicken or beef burgers) often have a <strong>gross margin</strong> (not including labor, rent, insurance, and other non-food costs) of about <strong>~40%</strong> while fries have a <strong>gross margin as high as 95%</strong> - so packaging these various items together is what enables profitable outcomes</li>
      <li>Now other QSRs are following suit like <strong>Burger King’s $5 “Your Way Meal”</strong> and <strong>Jack in the Box’s “Munchies under $4”</strong> deal</li>
    </ul>
  </li>
  <li><strong>McDonald’s franchisees never own the real estate</strong> – that is owned by either corporate or by another landlord who leases it to corporate which passes on the rent expense to the franchisee
    <ul>
      <li>In instances where corporate owns the real estate, the franchisee pays a fixed % of sales every month as rent which corporate usually keeps constant and doesn’t raise; in situations where the land is owned by someone else, then the franchisee must deal with the <strong>landlord raising rents</strong> as they see fit</li>
      <li>Unlike McDonald’s, some competitors like Burger King allow franchisees to own the underlying property but sometimes franchisees can perform poorly which allows competing franchisors to come in and swoop up the land in the event of a bankruptcy or liquidation</li>
      <li>Older stores tend to have <strong>lower rent percentages (around 8.5%)</strong> due to lower initial real estate costs while newer stores may have <strong>higher rent percentages (13%-15%)</strong> due to higher initial real estate investments by McDonald's</li>
    </ul>
  </li>
  <li>Franchised sites are valued based on <strong>cash flows</strong> and their valuation can range between <strong>5-10x</strong> based on the site’s sales, margins, and growth potential as informed by the local demographics and competitive landscape – making each store <strong>“unique like a snowflake”</strong>
    <ul>
      <li>Despite increased upfront investments and operational costs, McDonald’s franchises remain attractive to buyers due to the <strong>stable and predictable rent structure</strong> – and buyers often see opportunities to improve profitability by optimizing operations and costs</li>
    </ul>
  </li>
  <li>Technological improvements over the past five years include back-office management, revenue management, scheduling, and kitchen equipment – they have been <strong>labor-neutral</strong> and have primarily improved product quality as opposed to reducing labor costs
    <ul>
      <li>Much focus has gone towards <strong>Service Area Optimization (SAO)</strong> which calls for the removal of beverage bars from dining rooms and places them behind the counter which allows for more seating and ordering kiosks while also reducing waste</li>
      <li>The franchise system-wide app features both national and local promotions – local events like sports achievements can trigger specific promotions (e.g., free nuggets if a local team scores a certain number of points)</li>
    </ul>
  </li>
  <li>There is an increasing trend of corporate passing on more costs and responsibilities to the franchisees as they have shifted from providing extensive field support to cutting down on these services
    <ul>
      <li>This development has gone hand in hand with the fact that corporate <strong>eliminated its 10 field offices</strong> in the US and centralized operations in Chicago in accordance with McKinsey’s counsel which means that the field staff work at home and <strong>interact less with the franchisees</strong></li>
    </ul>
  </li>
  <li>McDonald’s has struggled to achieve success in the <strong>coffee category</strong>, failing to meet corporate targets with the main competition being <strong>Starbucks</strong> which has a strong brand and market presence
    <ul>
      <li>The overall coffee category has started to buckle under <strong>customer’s price sensitivity</strong> as Starbucks experienced a <strong>3.5% year-over-year decline</strong> in sales in Q1 2024 as consumers are less willing to pay higher prices for such beverages</li>
      <li>Despite Starbucks’s dominance, McDonald's provides a value proposition by offering meals along with beverages, which may be more appealing to <strong>price-conscious customers</strong></li>
      <li>Corporate is exploring a new concept called <strong>CosMc</strong> which focuses on drinks and drive-through services, but details are limited as McDonald's is not sharing much information yet – nonetheless McDonald’s still suffers from a perception issue as its coffee is not seen as <strong>“premium” like Starbucks’s</strong></li>
    </ul>
  </li>
  <li>Many McDonald's operators are <strong>generational</strong>, with 2nd or 3rd-generation owners running the business
    <ul>
      <li>All <strong>6 of the interviewee’s managers</strong> started as crew members and have stayed on for many years – underscoring the <strong>talent retention</strong> within the franchise system</li>
    </ul>
  </li>
</ul>
`,
        ],
        image: "/spotlight/mcd_3.png",
      },
      {
        id: 2,
        title: "Spotlight #2 – McDonald's Franchise Owner – June 2022",
        description:
          "Discussion focuses on store unit economics, pricing algorithms, the impact of weight-loss GLP-1 drugs, the ongoing modernization drive, and salient items regarding the relationship between franchisees and corporate",
        points: [
          `
<ul>
  <li>Interviewee’s family started its affiliation with McDonald’s in <strong>1976</strong> when his father flipped burgers at a site, working his way up the corporate ladder for <strong>35 years</strong> to become a company director, and then started owning franchised sites in <strong>2020</strong> (the family now operates <strong>40 different locations</strong>)
    <ul>
      <li>Interviewee also worked for <strong>corporate’s pricing department</strong> for a while and helped to set the pricing in several international markets; has then gone on to operate his own sites for the <strong>past 10 years</strong></li>
      <li>The franchisee’s stores have <strong>outperformed the market</strong> over the past decade by achieving sales growth of <strong>4% higher</strong> than the average US McDonald’s restaurant sales growth in recent quarters due to strategic pricing decisions and value offerings</li>
    </ul>
  </li>
  <li>Maintained <strong>competitive pricing</strong>, particularly on value items, unlike other operators who have leaned into price hikes which ends up harming traffic over the longer-term
    <ul>
      <li>The most important line-item in a McDonald’s franchisee’s P&L is <strong>Profit After Controllables (“PAC”)</strong> – normal PAC margins used to be <strong>32%</strong> but this climbed to <strong>42%</strong> during Covid which several operators are still trying to chase through outrageous price increases</li>
      <li>Interviewee’s decision to keep <strong>value-based pricing</strong> and not engage in hikes has allowed him to chip away at his local competing Burger King and Wendy’s</li>
      <li>Historically operators have increased pricing year-over-year at <strong>~3%</strong> to keep pace with inflation but over the past 2.5 years the interviewee has increased prices cumulatively by <strong>~10%</strong> while other operations have done <strong>14-15%</strong></li>
    </ul>
  </li>
  <li>Corporate has invested significant resources in its pricing algorithms and franchisees additionally work with companies like <strong>Revenue Management Solutions (RMS)</strong> and <strong>Deloitte</strong> to further validate their pricing strategies
    <ul>
      <li>McDonald’s will likely continue to have a few more years of <strong>high single digit price increases (7-9%)</strong> unless there is serious deflation in commodity prices but if wage inflation creeps back up then <strong>+10% pricing</strong> wouldn’t be out of the question</li>
    </ul>
  </li>
  <li>There are emerging threats from regional (e.g., Whataburger, In-N-Out) and non-traditional (e.g., Wawa) players who are expanding into new markets but after the initial excitement these concepts <strong>do not sustain higher customer interest</strong>
    <ul>
      <li>The presence of popular coffee chains like Starbucks and Dutch Bros. can sometimes boost McDonald's breakfast sales, as they attract customers to the area and gravitate towards McDonald’s <strong>superior breakfast sandwich</strong></li>
    </ul>
  </li>
  <li>Interviewee has not seen any impact to his operations stemming from the increasing adoption of <strong>weight-loss GLP-1 drugs</strong>
    <ul>
      <li><strong>GLP-1s</strong> are expensive for the average American and are more likely to impact the demographic of a concept like Chick-fil-A (slightly more affluent customer base) than McDonald’s; far larger portion of McDonald’s customers make less then <strong>$100k</strong> vs the portion of Chick-fil-A’s customer base in that low-income bracket</li>
      <li>If this starts to have an impact there is enough <strong>menu flexibility</strong> to where operators could emphasize healthier options like chicken salads and parfaits</li>
    </ul>
  </li>
  <li>In terms of operating leverage, if the franchisee saw a sales reduction of <strong>10%</strong> then that would probably translate to about <strong>~200-300 bps of PAC margin deterioration</strong>
    <ul>
      <li>Interviewee’s average store from his <strong>60 sites</strong> does <strong>$3.6M</strong> in sales annually and generates about <strong>~$600k in cash flow per year</strong> (<strong>~17% margins</strong>)</li>
      <li>Had to remodel <strong>37 of his 40 stores</strong> over the past <strong>4 years</strong> due to a nation-wide modernization campaign that corporate was spearheading</li>
    </ul>
  </li>
  <li>Corporate has been highly focused on increasing <strong>digital transactions</strong> which are now approaching <strong>30% of all transactions</strong> within the franchise system
    <ul>
      <li>The McDonald’s <strong>digital app</strong> is likely to surpass even <strong>Starbucks’s</strong> in terms of both user engagement and transaction volume</li>
      <li>Despite the success of these digital initiatives, there is concern of corporate’s trimming of the <strong>digital workforce</strong> which has impacted the agility of the digital strategy (reduced ability to quickly implement and change offers)</li>
    </ul>
  </li>
  <li>Sometimes there are complications in the relationships between corporate and the franchisees – especially regarding the handling of <strong>graded visits</strong> (known as “RGRs” – Restaurant Graded Reviews) but this was not discussed at all unfortunately at the last system-wide meeting in Las Vegas
    <ul>
      <li>Interviewee is frustrated that he spends <strong>+$15k on every restaurant</strong> before a graded visit which otherwise could go towards increasing customer count by <strong>3-4%</strong></li>
      <li>Corporate is already taking <strong>16-18% of sales</strong> in rent and now adding another point of <strong>royalty fees</strong> for new franchisee (going from <strong>4% of sales to 5%</strong>)</li>
    </ul>
  </li>
</ul>
`,
        ],
        image: "/spotlight/mcd_2.jpeg",
      },
      {
        id: 3,
        title: "Spotlight #3 – McDonald's Franchise Owner – June 2022",
        description:
          "Provides a detailed overview on the interplay of McDonald’s digitization strategy and the 3rd party food delivery aggregators (e.g., Uber Eats) while also exploring implications for customer loyalty, logistical efficiencies, and the broader automation of franchisee workflows",
        points: [
          `
<ul>
  <li>Expert worked at Corporate from <strong>2014 to 2021</strong> and was instrumental in the roll-out of the digital strategy with an emphasis on the <strong>mobile app, kiosks, and website</strong>
    <ul>
      <li>Also led the global product team for the mobile app, overseeing initiatives like mobile offers, ordering ahead, curbside pickup, and the <strong>integration of Uber Eats</strong> (allowing for Uber Eats orders to be fulfilled without the need for a separate iPad)</li>
      <li>Managed <strong>two distinct apps</strong>: one for the US and one for <strong>30 other countries</strong> – the international app had a lighter feature set and lacked certain capabilities like order-ahead which were present in the US version</li>
    </ul>
  </li>
  <li>McDonald's did not have a <strong>dedicated digital team</strong> until the end of 2014 (a little late to the trend relative to competitors); the focus was to add value through digital channels, including developing a mobile app and integrating third-party delivery services like <strong>Uber Eats</strong>
    <ul>
      <li>McDonald's primarily worked with <strong>Uber Eats</strong>, with some initial involvement from <strong>Postmates</strong> - focus was on ensuring operational consistency and food quality, including packaging modifications and training for restaurant staff</li>
      <li>The integration of delivery required <strong>new procedures</strong>, such as sealing bags with stickers and adjusting menu offerings to exclude items not suitable for delivery; McDonald's also <strong>limited delivery radii</strong> to ensure food quality, often conflicting with delivery partners' desires to <strong>expand the delivery range</strong></li>
      <li>Prioritized <strong>maintaining food quality</strong>, even for delivery orders - the company faced challenges in managing customer expectations around delivery fees, which are a common point of contention among QSR customers</li>
    </ul>
  </li>
  <li>At the time of the expert's tenure, the <strong>vast majority of delivery orders</strong> were placed through <strong>Uber Eats</strong> rather than McDonald's app (~<strong>90%</strong>) - the integration with Uber Eats allowed McDonald's to reach customers more effectively than developing an in-house delivery system
    <ul>
      <li>In some international markets, particularly in <strong>Asia</strong>, McDonald's employs its own delivery drivers - this is due to longer market presence, greater population density, and higher demand, making it economically viable to manage delivery internally; the U.S. market, however, relies heavily on <strong>third-party platforms</strong> due to different market dynamics and cost considerations</li>
      <li>Unlikely for McDonald's to bring delivery in-house in the U.S. due to existing networks of third-party delivery drivers and the high costs and liabilities associated with managing an in-house fleet; therefore, the domestic priority was more on <strong>operational efficiency</strong> within restaurants rather than managing the last mile of delivery</li>
    </ul>
  </li>
  <li>There may be a potential market shift between using <strong>aggregator platforms</strong> (like Uber Eats) and <strong>proprietary apps</strong> (like the Starbucks app); while McDonald's currently benefits from aggregators' wide reach, there may be a future where proprietary apps become more prevalent, depending on consumer preferences and market trends
    <ul>
      <li>McDonald's viewed delivery as an <strong>incremental channel</strong> rather than a primary method for building customer loyalty; can be compared to the experience of visiting a McDonald's in an airport—serving immediate needs rather than fostering long-term brand loyalty</li>
      <li><strong>Taco Bell</strong> and <strong>Burger King</strong> do not have the same focus on creating a routine customer experience while McDonald's strategy emphasized <strong>consistent, comforting food</strong> at an affordable price, particularly during economic downturns</li>
    </ul>
  </li>
  <li>Corporate has shown interest in <strong>automation</strong>, including exploring drone delivery and <strong>automated drive-thru systems</strong>; they are continually seeking ways to reduce labor costs and improve efficiency, aligning with their broader strategic goal
    <ul>
      <li>McDonald’s was working on <strong>drone projects</strong> as early as 2015 but these initial iterations were killed due to technological limitations at the time</li>
    </ul>
  </li>
</ul>
`,
        ],
        image: "/spotlight/mcd_1.avif",
      },
      {
        id: 4,
        title: "Spotlight #4 – McDonald's Franchise Owner – June 2022",
        description:
          "Highlights the evolution of McDonald’s new store prototype as well as the impact of the digital mobile application on Corporate’s intentional shift towards the smaller store footprint coupled with the automation of internal workloads",
        points: [
          `
<ul>
  <li>Covid-19 influenced a shift towards <strong>smaller, more efficient setups</strong> while the rise in digital ordering platforms and <strong>3rd-party delivery services</strong> have significantly contributed to <strong>higher AUVs</strong> (Average Unit Volumes) which have been a <strong>major attraction for new franchisees</strong> (McDonald’s AUVs are now near the top of the QSR sector)
    <ul>
      <li>The new smaller McDonald’s prototypes have led to <strong>lower build-out costs</strong> with <strong>faster payback periods</strong> – the focus from corporate over the next few years is to have new and current franchisees open as many of these more capital-efficient sites as possible</li>
    </ul>
  </li>
  <li>Revenue growth for McDonald’s sites has been <strong>better than pre-Covid levels</strong> due to the combination of <strong>increased pricing and traffic</strong> – but the most important part of developing new sites is always <strong>finding the right real estate</strong> (which increasingly are in suburban locations)</li>
  <li>The relationship with corporate is positive and they have made tremendous strides with the <strong>roll-out of the digital app</strong> which has garnered a <strong>cult-like following</strong> and has directly been responsible for more order volume per customer
    <ul>
      <li>Corporate also handles <strong>food delivery mishaps directly</strong> with the 3rd party aggregator services (e.g., Uber Eats, DoorDash)</li>
    </ul>
  </li>
  <li>Digitization of workloads at the new buildouts meaningfully increases employee productivity by <strong>automating communication from the point-of-sale to the production line</strong> – this process involves a load station (orders are prepared) and a dress station (orders are completed) as orders taken on iPads by employees are instantly sent to respective stations in a seamless manner</li>
</ul>
`,
        ],
        image: "/spotlight/mcd_4.jpeg",
      },
      {
        id: 5,
        title: "Spotlight #5 - McDonald's Franchise Owner - May 2024",
        description:
          "Focuses on the shift from informal eating out to fast casual dining, with the lines between quick service and fast casual starting to blur while also touching upon the impact of inflation, store traffic trends, and franchisor-franchisee dynamics",
        points: [
          `
          <ul>
  <li>The expert has extensive experience in the food service and QSR industry, starting as a <strong>teenager working at McDonald's</strong> for a franchisee then later transitioned to corporate roles, including a regional insights position at McDonald's, where they worked for approximately <strong>25 years</strong> (their tenure ended in December 2018 due to a consolidation of field offices)
    <ul>
      <li>In the <strong>regional insights role</strong>, the expert's primary responsibilities included analyzing sales data, understanding <strong>sales trends</strong>, and evaluating the effectiveness of marketing programs - this role was closely tied to various departments, including <strong>finance, marketing, and even reporting to the CEO</strong> at times</li>
    </ul>
  </li>
  <li>McDonald's is the <strong>leading player in the QSR space</strong>, with Burger King and Wendy's historically vying for the second and third spots as McDonald’s strength lies in its <strong>widespread locations</strong>; However, Chick-fil-A has emerged as a significant competitor, especially in terms of <strong>average unit volume (AUV)</strong> and consumer perception
    <ul>
      <li>Chick-fil-A's strong <strong>brand loyalty</strong>, perceived higher food quality, and superior customer service have made it a formidable competitor, potentially surpassing McDonald's in certain metrics</li>
      <li>QSR landscape has seen a shift with <strong>Chick-fil-A and fast-casual brands like Chipotle gaining market share</strong>; Chick-fil-A is particularly strong among families and children, who perceive its offerings as healthier compared to traditional fast-food options - this perception has been a significant factor in its appeal, despite actual nutritional content comparisons</li>
    </ul>
  </li>
  <li>McDonald's has struggled with <strong>declining traffic</strong>, with only one year of growth out of the last ten years before 2021; the category of informal eating out, which includes QSRs, is generally in decline, with consumers increasingly gravitating towards fast-casual options like Chipotle, driven by perceptions of healthier and higher-quality food
    <ul>
      <li>The lines between QSR and fast-casual pricing have blurred, with <strong>Chipotle often being perceived as offering better value</strong> despite similar or higher prices - this shift has partly resulted from rising operational costs in the QSR industry, necessitating price increases</li>
      <li>McDonald's has made efforts to improve food quality, including testing <strong>plant-based options like the McPlant Burger</strong>; however, overcoming long-standing perceptions of being a <strong>low-quality, value-driven brand</strong> remains a challenge</li>
      <li>Has lagged behind competitors like <strong>Starbucks and Chipotle</strong> in digital innovation, particularly in mobile app functionalities, order-ahead capabilities, and loyalty programs. However, the company has been catching up, albeit slowly</li>
    </ul>
  </li>
  <li>The relationship between McDonald's corporate and its franchisees has fluctuated, with periods of tension, particularly under previous leadership; franchisees have significant control over pricing and labor decisions, and there has been a shift from a <strong>family-like culture</strong> to a more <strong>business-centric approach</strong>
    <ul>
      <li>McDonald's <strong>handles procurement</strong> for its franchisees, leveraging its scale for cost efficiencies; however, franchisees have autonomy over menu pricing, which they use to manage their P&Ls, especially in the face of inflation and rising costs</li>
    </ul>
  </li>
  <li><strong>Inflation has pressured margins</strong> across the QSR industry; while McDonald's corporate structure, primarily franchised, insulates it from some direct cost pressures, the overall cost of doing business has increased, affecting franchisees' profitability
    <ul>
      <li>Historically, McDonald's has performed well during recessions due to its value positioning; however, with rising food costs and the blurring of price lines with fast-casual competitors, the company's value proposition may not be as strong as in previous downturns</li>
    </ul>
  </li>
  <li>The expert noted a transition from the <strong>"old guard"</strong> at McDonald's to a younger leadership team; while this brings fresh perspectives, it also means a loss of historical knowledge and experience, potentially <strong>affecting strategic continuity</strong></li>
</ul>
          `,
        ],
        image: "/spotlight/mcd_5.png",
      },
      {
        id: 6,
        title: "Spotlight #6 - McDonald's Franchise Owner - July 2024",
        description:
          "Overview of McDonald’s strategic moat within QSR enabled by its savvy real estate selection coupled with its unparalleled supply chain network",
        points: [
          `
          <ul>
  <li>The expert's tenure at McDonald's spanned from <strong>2007 to 2019</strong> where he started as a <strong>Finance Manager</strong> for the New England and New York regions and quickly ascended to the role of <strong>Finance Director for the Southeast U.S. region</strong>, which included South Carolina, Alabama, Florida, and Key West
    <ul>
      <li>He managed a team of four Finance Managers and was responsible for the financial health and strategic oversight of over <strong>1,700 locations</strong>, generating more than <strong>$4.4 billion in annual revenue</strong>; the role encompassed overseeing financial results, identifying trends, and advising on franchising strategies</li>
      <li>Provided comprehensive <strong>financial risk evaluations</strong> and proposed strategic alternatives to the U.S. leadership team and regional VP</li>
      <li>The expert was also pivotal in implementing the <strong>"Experience of the Future"</strong> initiative, which modernized restaurants and enhanced service experiences, leading to a <strong>6% increase in incremental annual average sales per restaurant</strong> in its first year</li>
    </ul>
  </li>
  <li>McDonald's differentiates itself through its <strong>consistent value pricing and prime real estate locations</strong>; the company's scale allows it to negotiate favorable terms with suppliers, ensuring low food costs
    <ul>
      <li>McDonald's significant cash flow and reputation as a <strong>stable tenant</strong> enable it to secure ideal real estate locations, often on <strong>premium sites with high visibility and accessibility</strong> - these strategic advantages, combined with a vast and efficient supply chain, contribute to McDonald's ability to maintain a <strong>competitive edge</strong> in the fast-food industry</li>
      <li>Market share in the U.S. has remained relatively stable, although the rise of <strong>quick-casual brands like Chipotle and Panera Bread</strong> and QSRs like <strong>Chick-fil-A</strong> has eroded guest counts; expert noted that if Chick-fil-A were to expand significantly, particularly by opening on Sundays or attracting substantial investment, it could pose a serious threat to McDonald's dominance – but despite these challenges, McDonald's <strong>extensive market penetration</strong> and financial resources position it well to <strong>withstand competitive pressures</strong></li>
    </ul>
  </li>
  <li>McDonald's approach to innovation is <strong>cautious and methodical</strong>, balancing the need for new products with the operational complexities they introduce; the company has a dedicated team of chefs and research professionals who develop and test new menu items, considering factors like supply chain capacity and franchisee buy-in
    <ul>
      <li>However, franchisees in the U.S. have been <strong>resistant to adding complex menu items</strong> that could slow down service and reduce throughput, a critical factor in profitability</li>
    </ul>
  </li>
  <li>The current CEO of corporate is <strong>strategic and forward-looking</strong> and has focused on maintaining McDonald's relevance in a rapidly changing market; however, his <strong>private equity background</strong> has sometimes led to a more top-down management style, which can clash with the <strong>franchise-based nature of McDonald's operations</strong></li>
  <li>In the U.S., McDonald's faces ongoing challenges related to <strong>public perceptions of its food as unhealthy</strong> - this is compounded by the broader industry trend toward healthier eating and the rise of <strong>quick-casual competitors</strong> offering more health-conscious options
    <ul>
      <li>Potential for labor issues, such as <strong>unionization and minimum wage increases</strong>, poses a significant risk - higher labor costs could compress margins, forcing McDonald's to raise prices or reduce profitability</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/mcd_6.png",
      },
    ],
  },
  {
    slug: "Wingstop",
    pageData: {
      pic: "/logos/Wing-Stop.jpg",
      title: "Wingstop",
      initialInvestment: "$325k-$975k",
      franchiseeFee: "$20k",
      royaltyRate: "6.0%",
      description:
        "Wingstop Inc. is a prominent player in the fast-casual dining segment, specializing in chicken wings. Founded in 1994, Wingstop offers a variety of wing flavors along with a selection of sides, catering to a broad customer base. The company operates over 1,500 locations worldwide, leveraging a franchising model that emphasizes simplicity in operations and consistent product quality. Wingstop&#39;s commitment to freshly prepared, never-frozen wings and a streamlined menu has positioned it as a leader in the wing segment, appealing to customers seeking quick, flavorful meals. The brand&#39;s strategic focus on digital and delivery services has further enhanced its accessibility and convenience.",
    },
    keyTrends: [
      {
        leftDescription:
          "The <strong>introduction of the chicken sandwich</strong> has significantly boosted sales and attracted a younger demographic; this product has been well-received, complementing Wingstop's core menu and <strong>driving lunchtime traffic</strong>",
        rightDescription:
          "<strong>Introducing advanced customer tracking systems</strong> beyond basic order information can help track repeat purchase behavior – currently difficult to assess loyalty and retention",
      },
      {
        leftDescription:
          "Wingstop's investment in digital ordering platforms has paid off, with over <strong>70% of sales now coming through online and delivery channels</strong>; this digital emphasis has streamlined operations and improved customer convenience",
        rightDescription:
          "The <strong>rise of ghost kitchens and virtual concepts</strong> have not been able to erode Wingstop’s loyalty base but should be monitored to see how the overall fast casual space will mature",
      },
      {
        leftDescription:
          "The brand has seen <strong>tremendous increase in awareness</strong> due to savvy advertising at <strong>high-profile sporting events</strong> – helping the concept expand beyond its historic customer niche",
        rightDescription:
          "While the Wingstop brand continues to grow, there are concerns <strong>about market saturation</strong>, particularly in established regions; careful management of new store openings is necessary to avoid <strong>cannibalizing existing locations</strong>",
      },
    ],
    cardData: [
      {
        id: 1,
        title: "Spotlight #1 - Wingstop Franchise Owner - February 2024",
        description:
          "Exploration of operational-specific initiatives the multi-unit owner has taken to improve the economics of both new and mature units and an outline of Wingstop’s competitive differentiation amidst the chicken wing category",
        points: [
          `
          <ul>
  <li>The franchise owner operates multiple Wingstop locations, with a recent store opening in February exceeding initial sales expectations; the store experienced a strong start, hitting <strong>$42,000 per week</strong> before stabilizing around <strong>$24,000-$25,000</strong>
    <ul>
      <li>The <strong>"honeymoon phase"</strong> typically seen in new store openings was followed by a gradual settling of sales figures, indicating strong market entry but the need for consistent customer engagement</li>
    </ul>
  </li>
  <li>Existing stores have also seen significant improvements in performance; a previously underperforming location now averages <strong>$27,000 weekly</strong>, up from <strong>$10,000</strong>, showcasing effective management and customer service strategies
    <ul>
      <li>Improvements can be attributed to <strong>refined operational practices</strong>, enhanced customer service, and better local market penetration</li>
    </ul>
  </li>
  <li>A notable reduction in food costs has been achieved, dropping from the <strong>high 30% range</strong> to the <strong>high 20% range</strong>; this is a result of strategic supplier negotiations and efficient inventory management by Wingstop
    <ul>
      <li>Despite initially higher costs compared to competitors, Wingstop's approach has secured price stability, safeguarding against future market fluctuations and potential supply chain disruptions</li>
    </ul>
  </li>
  <li>Wingstop distinguishes itself in the quick-service restaurant (QSR) sector by emphasizing <strong>"selling flavor"</strong> rather than just chicken, leveraging a diverse and unique menu to attract a wide customer base
    <ul>
      <li>Limited-time offers and promotional items, such as the chicken sandwich, have been pivotal in drawing non-wing consumers, boosting lunch sales to account for <strong>25% of daily transactions</strong></li>
    </ul>
  </li>
  <li>Buffalo Wild Wings, particularly with its new Buffalo Wild Wings GO concept, poses the most direct competition; however, Wingstop maintains a competitive edge through a <strong>streamlined menu</strong> and efficient operational model
    <ul>
      <li>Unlike Buffalo Wild Wings GO, which includes a broader menu with items like burgers and salads, Wingstop <strong>focuses exclusively on chicken</strong>, allowing for a more efficient kitchen operation and lower operational costs</li>
    </ul>
  </li>
  <li>Despite the potential <strong>overlap in customer bases</strong>, Wingstop remains confident in its ability to provide superior product quality and customer service, thereby retaining customer loyalty even when faced with direct competition</li>
  <li>Wingstop's operations are characterized by a straightforward menu and a focus on digital orders, which constitute over <strong>70% of sales</strong>; this digital integration allows for efficient kitchen management and quick adaptation to customer needs
    <ul>
      <li>The digital focus not only enhances order accuracy but also improves customer wait times by allowing <strong>precise kitchen scheduling</strong></li>
    </ul>
  </li>
  <li>The company has adopted new technologies selectively, ensuring that investments contribute to improved product quality without unnecessary complexity
    <ul>
      <li>For example, while Buffalo Wild Wings GO invests heavily in <strong>advanced kitchen technology</strong>, Wingstop prioritizes <strong>consistency and simplicity</strong>, maintaining a focus on freshly prepared food that meets customer expectations</li>
    </ul>
  </li>
  <li>The franchise owner expressed concerns about the potential impact of <strong>macroeconomic factors</strong>, such as a severe recession or rising labor costs, particularly in states like <strong>California and New York</strong> where minimum wage increases are imminent
    <ul>
      <li>A <strong>$20 minimum wage</strong> in California, for instance, could significantly raise operational costs, impacting profitability; similar policies in New York could follow, affecting nearby New Jersey operations</li>
    </ul>
  </li>
  <li>Despite these challenges, the franchise's robust business model, characterized by <strong>high AUVs</strong> and a strategic focus on maintaining high-quality customer service, provides a buffer against economic downturns
    <ul>
      <li>The owner is prepared to absorb a potential <strong>10% drop in sales</strong>, indicating strong financial planning and a commitment to maintaining service standards without compromising on quality</li>
    </ul>
  </li>
  <li>The interview also highlighted interest in emerging restaurant concepts like <strong>Tropical Smoothie Cafe</strong>, emphasizing the importance of diversification and exploration of new business opportunities
    <ul>
      <li>Tropical Smoothie Cafe's <strong>rapid growth and diverse menu offerings </strong>present an attractive model, though territory availability and market fit are crucial considerations for expansion</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/wingstop_1.webp",
      },
      {
        id: 2,
        title: "Spotlight #2 - Wingstop Franchise Owner - March 2024",
        description:
          "Ex-hotelier explains his preference for Wingstop’s resilient fast-casual model over the extreme cyclicality of the hospitality sector; he also believes that “saturation” is ultimately a good thing because minor cannibalization is more than offset by the increased strength in brand awareness",
        points: [
          `
          <ul>
  <li>The franchise owner transitioned from a corporate job to the <strong>hotel business in 2004</strong>, driven by a desire for independence; starting with a hotel acquisition in the South, they gained valuable experience and insights into community engagement and business operations
    <ul>
      <li>The economic downturn in 2008 prompted a temporary exit from the hotel industry, but by 2011, they returned to hotel management; in 2014, they sold their second hotel and sought new opportunities in the restaurant sector, leading them to Wingstop</li>
      <li>The decision to enter the Wingstop franchise was influenced by several factors, including the <strong>brand's appeal in high-cost real estate areas</strong> like the Northeast, the <strong>simplicity of its menu</strong>, and the <strong>ease of operations with a small footprint</strong>; the typical Wingstop location is around <strong>1,500 square feet</strong>, though recent trends favor even smaller units due to the predominance of takeout and delivery services</li>
    </ul>
  </li>
  <li>The franchise owner's <strong>first Wingstop location opened in 2016</strong>, followed by a <strong>second in early 2017</strong> - the initial location <strong>quickly broke even</strong>, indicating strong market demand; however, the second location, in a less optimal area, faced slower growth until the opening of a <strong>nearby Taco Bell in 2019</strong> boosted traffic and sales
    <ul>
      <li>The owner emphasizes the importance of efficient order management, particularly as takeout and delivery dominate, comprising about <strong>95% of sales post-pandemic</strong>; proximity to a Taco Bell drive-through highlights the <strong>efficiency and convenience of Wingstop's online ordering</strong> and quick <strong>pickup</strong> process</li>
    </ul>
  </li>
  <li>On average, the stores handle between <strong>100 to 180 transactions daily</strong>, with weekend sales being higher; the franchise owner estimates weekly sales at around <strong>$20,000</strong>, with an average ticket size of <strong>$20</strong>; larger orders, such as those for group events, are common on weekends and evenings
    <ul>
      <li>The business's growth strategy involves analyzing customer segments, though specific data on customer demographics and order patterns has only recently become available from corporate; the owner notes that while beverage sales are generally low, comprising <strong>less than 5% of total sales</strong>, the inclusion of <strong>Coca-Cola Freestyle machines</strong> aligns with Wingstop's branding focus on offering a variety of flavors</li>
    </ul>
  </li>
  <li>The protein, particularly chicken wings, accounts for about <strong>70% of sales</strong>, with a notable shift towards <strong>boneless wings and tenders</strong> due to volatility in <strong>bone-in wing pricing</strong>; this shift has been encouraged by pricing strategies that make boneless options more attractive to customers - the franchise currently sees a <strong>50-50 split</strong> between bone-in and boneless products
    <ul>
      <li>The franchisee has observed significant <strong>price inflation</strong> in the cost of both boneless and bone-in wings, necessitating regular price adjustments; recent changes have included a <strong>7% price increase on boneless wings</strong>, partly to balance costs and steer customers back towards bone-in options, which have become comparatively less expensive</li>
    </ul>
  </li>
  <li>The franchise owner discusses the complexities of pricing strategies and regional differences; while Wingstop provides guidance, franchisees have the <strong>autonomy to adjust prices</strong> based on local conditions - despite substantial price increases in the past year, overall <strong>sales and profitability</strong> have remained strong, suggesting that the pricing strategy has been effective
    <ul>
      <li>Reflecting on the impact of the COVID-19 pandemic, the franchise owner reports significant sales growth since 2019, with one store seeing a <strong>25-30% increase</strong> and another experiencing a <strong>45-50% rise</strong>, largely due to <strong>increased brand awareness</strong> and new customer acquisition through advertising, including television</li>
    </ul>
  </li>
  <li>The owner highlights the <strong>importance of brand recognition</strong>, noting that recent television advertising has significantly boosted new customer traffic; about <strong>30% of these new customers have become regulars</strong>, defined as visiting multiple times per quarter - investment in advertising, particularly in high-cost markets like the New York City metro area, is seen as <strong>crucial despite the high costs</strong>
    <ul>
      <li>The franchise's <strong>focus on premium locations</strong> over cheaper alternatives has been key to its strategy; the first two stores were carefully chosen for <strong>high visibility and traffic</strong>, with one benefiting from being near <strong>major retailers</strong> and a new Taco Bell</li>
    </ul>
  </li>
  <li>The cost of building out new Wingstop locations has risen significantly, from around <strong>$400,000-$450,000</strong> to potentially over <strong>$600,000</strong> due to increased costs of equipment, labor, and materials; the franchise owner notes the challenges of navigating <strong>local regulations</strong>, especially in <strong>older buildings</strong>
    <ul>
      <li>Operationally, Wingstop locations are equipped with a <strong>minimum of five fryers</strong>, allowing them to handle <strong>weekly sales of up to $45,000</strong>; however, expansion within existing stores is limited by <strong>physical space constraints</strong></li>
    </ul>
  </li>
  <li><strong>Territory and market saturation</strong> are significant considerations - while the owner acknowledges the potential cannibalization from nearby Wingstop locations, they believe it ultimately <strong>benefits the brand by increasing overall market presence</strong>; the franchise owner has observed that the <strong>corporate strategy now favors multi-unit operators</strong>, reducing the likelihood of new single-unit franchisees entering established markets
    <ul>
      <li>The owner plans to <strong>expand from 3 to 5 locations</strong>, highlighting the evolving market dynamics and increased brand awareness that now make previously less viable territories more attractive</li>
    </ul>
  </li>
  <li>The franchise owner expresses <strong>confidence in Wingstop's long-term prospects</strong>, noting the stability provided by experienced multi-unit operators and the brand's continued growth; they also acknowledge the challenges posed by rising costs and the need for careful financial planning, particularly in <strong>managing debt and cash flow</strong>
    <ul>
      <li>Despite current economic uncertainties, the owner sees continued opportunities for growth, particularly as Wingstop's <strong>brand awareness increases</strong> and new markets open up</li>
    </ul>
  </li>
  <li>The discussion concludes with reflections on the differences between the hotel and restaurant industries, with the owner noting the <strong>cyclical nature and intense competition in the hotel sector</strong>; they express a preference for the <strong>restaurant business</strong>, citing its potential for consistent growth and expansion</li>
</ul>

          `,
        ],
        image: "/spotlight/wingstop_2.webp",
      },
      {
        id: 3,
        title: "Spotlight #3 - Wingstop Franchise Owner - May 2024",
        description:
          "A look into Wingstop’s historic target demographic and how that has evolved over time, culminating into a loyal customer base with a robust brand that has been further bolstered by episodes of high-profile national advertising",
        points: [
          `
          <ul>
  <li>The franchise owner operates <strong>3 Wingstop locations on the East Coast</strong>, with the first opening in 2016, followed by the second in 2017; a third location has recently opened after <strong>significant delays</strong> due to building regulations and supply chain issues
    <ul>
      <li>Initial success was marked by the <strong>first location breaking even on day one</strong>, with consistent growth in sales; the <strong>second location faced challenges</strong> due to its suburban setting and demographic nuances but eventually gained traction after the introduction of a <strong>nearby Taco Bell</strong> and delivery options</li>
      <li>The newest location is in a <strong>110-year-old building</strong>, presenting unique challenges due to <strong>historical preservation regulations</strong> and pandemic-related <strong>construction delays</strong></li>
    </ul>
  </li>
  <li>Wingstop has traditionally relied on a demographic base of <strong>Hispanic and African American customers</strong>; however, the brand has been expanding its appeal, particularly with the introduction of <strong>delivery services</strong>
    <ul>
      <li>The company's market strategy initially targeted high Hispanic areas, specifically <strong>Mexican communities</strong>, which proved more successful compared to other Latin American demographics</li>
      <li>The franchise has noticed a shift with the inclusion of delivery services, which have attracted a <strong>broader demographic with higher disposable incomes</strong>, increasing the average ticket size for delivery orders to around <strong>$27-$28</strong> compared to <strong>$20-$21 in-store</strong></li>
    </ul>
  </li>
  <li>The franchisee has navigated <strong>volatile wing costs</strong>, which peaked at <strong>$130 per case</strong> during 2021; current costs have <strong>stabilized between $55 and $75 per case</strong>, reflecting a significant decrease from the peak and even below 2019 levels
    <ul>
      <li><strong>Price increases</strong> have been implemented strategically, with a total rise of around <strong>20% in the Northeast over the past two years</strong> to offset rising labor costs and inflation; this includes a specific focus on adjusting boneless wing prices due to higher costs</li>
      <li>Despite challenges, the franchisee reports that current food costs are now around <strong>30%</strong>, a notable improvement from previous years, thereby enhancing overall profitability</li>
    </ul>
  </li>
  <li>A significant shift in Wingstop's advertising strategy has seen <strong>local ad funds redirected to a national push</strong>, resulting in increased brand visibility and market penetration, particularly through <strong>high-profile sports sponsorships</strong> and increased TV presence
    <ul>
      <li>The national focus has been beneficial, particularly in markets like the <strong>Northeast</strong>, which were newer to Wingstop and where local marketing efforts were less established</li>
    </ul>
  </li>
  <li>The introduction of the <strong>chicken sandwich</strong> has been a major success, initially <strong>boosting daily sales by 20%</strong> and contributing significantly to lunch traffic; this product has been particularly popular among younger demographics and has helped attract new customers
    <ul>
      <li>The sandwich, priced at <strong>$9</strong>, offers a competitive alternative to similar products from other fast-food chains, often <strong>providing better value</strong> with included dips and larger drink sizes</li>
      <li>The owner notes that the chicken sandwich has <strong>not overly complicated kitchen operations</strong>, aside from slight delays during peak times like the Super Bowl; training and equipment adjustments have been minimal, requiring only a new toaster</li>
    </ul>
  </li>
  <li>Wingstop continues to differentiate itself from competitors through its focus on <strong>freshly prepared wings and high-quality customer service</strong>; competitors like <strong>virtual brands and ghost kitchens</strong> have not significantly impacted Wingstop’s market position due to their limited advertising and delivery reach
    <ul>
      <li>The franchise owner expresses concerns about the potential for increased competition, particularly from ghost kitchens and other brands entering the wing market; however, Wingstop's established brand and <strong>loyal customer base</strong> provide a strong competitive advantage</li>
    </ul>
  </li>
  <li>The franchisee is <strong>optimistic about future growth</strong>, with plans to open additional locations and capitalize on the brand's growing popularity; challenges such as rising labor costs and economic uncertainties are acknowledged but are seen as manageable within the current business model
    <ul>
      <li>The potential for new product lines, such as <strong>plant-based options</strong>, is noted as a future consideration, though <strong>not currently a priority</strong> given the strong performance of existing menu items</li>
    </ul>
  </li>
  <li>There has been a noticeable shift in corporate policy towards <strong>stricter enforcement of development agreements</strong>, reflecting a broader push for growth and consistency across the franchise network; this includes potential contract enforcement and a focus on meeting growth targets
    <ul>
      <li>The owner appreciates the support and strategic direction provided by Wingstop's corporate leadership, highlighting effective management and communication, particularly in navigating market challenges and leveraging new opportunities</li>
    </ul>
  </li>
  <li>The interviewee also expresses interest in other fast-growing franchises, particularly <strong>Tropical Smoothie Cafe</strong>, noting its strong performance and appealing business model; however, current focus remains on expanding within the Wingstop brand
    <ul>
      <li><strong>Skepticism</strong> is expressed towards the <strong>salad market</strong> and other niche concepts, which are viewed as <strong>less stable</strong> and having <strong>lower barriers to entry</strong> compared to the structured and branded offerings of Wingstop</li>
    </ul>
  </li>
</ul>
          `,
        ],
        image: "/spotlight/wingstop_3.webp",
      },
      {
        id: 4,
        title: "Spotlight #4 - Wingstop Franchise Owner - July 2024",
        description:
          "Discussion of the pandemic-related gyrations that underscored Wingstop’s unique strengths with a simplified menu leveraged for a fast casual model; interviewee provides details on how margins vary across various poultry categories and expresses optimism for growth prospects",
        points: [
          `
          <ul>
  <li>The franchisee began their journey with Wingstop in 2014 and opened their first store in October 2015; they currently own <strong>6 Wingstop locations</strong>, with additional stores under development - their background also includes managing <strong>83 Burger King locations</strong> and involvement with <strong>Orangetheory Fitness</strong>, highlighting their broad experience in the franchise and fitness industries
    <ul>
      <li>Wingstop operates <strong>1,500 restaurants across 30 countries</strong> - the typical cost to open a Wingstop store ranges from <strong>$450,000 to $500,000</strong> depending on factors like location and build-out specifications; the average annual sales per store range between <strong>$950,000 and $1 million</strong>, with a noticeable increase in 2020 due to heightened delivery demand during the COVID-19 pandemic</li>
    </ul>
  </li>
  <li>Franchisees are subject to a <strong>6% royalty fee</strong> and a <strong>4% advertising fee</strong>, broken down into <strong>3% for national advertising</strong> and <strong>1% for local efforts</strong>; the initial franchise fee is <strong>$20,000</strong> for the first store, with subsequent stores requiring a <strong>$12,500 fee</strong> and an additional <strong>$10,000 territory development fee</strong> - these fees cover the right to use the Wingstop brand, access to their proprietary menu, and corporate support
    <ul>
      <li>Wingstop's competitive positioning is unique, with <strong>few direct national competitors</strong> - while Buffalo Wild Wings offers a similar product, it operates as a <strong>full-service restaurant</strong> rather than a fast-casual model; Popeyes, known for its fried chicken, and Chick-fil-A, another major player, focus on broader chicken offerings rather than exclusively on wings - this differentiation allows Wingstop to carve out a <strong>niche in the fast-casual market</strong> with a strong emphasis on <strong>fresh, never-frozen chicken wings</strong></li>
    </ul>
  </li>
  <li>The franchisee estimates that approximately <strong>80% of customers are repeat patrons</strong>; tracking customer loyalty is challenging due to a lack of advanced analytics tools - the current system does not collect comprehensive customer data beyond basic order information, making it difficult to measure repeat visits accurately; the franchisee expresses a desire for better customer tracking systems, such as a loyalty program, to enhance customer engagement and retention</li>
  <li>Wingstop has been <strong>proactive in adopting digital and delivery platforms</strong>, a strategy that has proven crucial, especially during the pandemic; the company initially lost partnerships with Uber Eats and Grubhub in 2019 due to a national agreement with <strong>DoorDash</strong> - however, following significant feedback from franchisees, particularly in regions where DoorDash's market presence was weak, Wingstop reinstated <strong>Uber Eats in March 2020</strong> - this decision, coinciding with the onset of the COVID-19 pandemic, significantly boosted sales as delivery became a vital revenue stream
    <ul>
      <li>Despite the added costs associated with delivery platform commissions (<strong>12% for DoorDash</strong> and <strong>10% for Uber Eats</strong>), these services are essential; they not only facilitate order processing but also increase average ticket sizes, which helps <strong>offset the commission fees</strong> - the delivery partnerships are integrated into Wingstop's point-of-sale (POS) systems, streamlining operations and enhancing customer convenience by allowing orders to be placed <strong>directly through Wingstop's website</strong></li>
    </ul>
  </li>
  <li>Pricing strategies vary by region, reflecting local economic conditions, labor costs, and market dynamics - for instance, Wingstop locations in Texas may have lower prices due to cheaper labor and supply costs compared to those in New York; the franchisee has some flexibility in setting prices, particularly on delivery platforms, where prices are slightly increased to cover additional costs - this strategy helps maintain profitability despite <strong>fluctuating poultry prices</strong>, which have been particularly volatile, with <strong>bone-in chicken wing prices reaching unprecedented highs</strong></li>
  <li>The franchisee discusses the product mix and margin considerations, noting that <strong>boneless wings and tenders offer the highest margins</strong>; in contrast, bone-in wings, while popular, have a <strong>higher cost structure</strong> due to their fresh nature - Wingstop's menu is streamlined, focusing primarily on chicken and a limited selection of sides and drinks; despite the simplicity of the menu, the franchisee feels there is room for innovation, particularly in developing new sides and flavors to attract customers and increase sales
    <ul>
      <li>Wingstop's promotional strategy is conservative, positioning itself as a <strong>non-discount brand</strong>; while promotions are infrequent, they do occur, typically focusing on items like boneless wings bundles - for example, a promotion might offer a <strong>boneless bundle for $16.99</strong>, which includes a set amount of wings, fries, and dips - these promotions are usually <strong>short-lived</strong>, running for about eight weeks, and are managed at the corporate level to maintain brand consistency</li>
    </ul>
  </li>
  <li>The COVID-19 pandemic has significantly shifted Wingstop's sales dynamics; prior to the pandemic, the split was roughly <strong>70% in-store orders to 30% delivery</strong> - this shifted to a <strong>50-50 balance</strong> during the pandemic, with delivery becoming a crucial component of the business - the franchisee anticipates that while some customers will return to dine-in, the convenience of delivery will continue to drive a significant portion of sales; this shift underscores the importance of maintaining strong digital and delivery infrastructures
    <ul>
      <li>Wingstop's approach to international expansion has been measured, with notable success in markets like <strong>Colombia, Mexico, and the UK</strong>; the franchisee is not directly involved in these international operations but acknowledges the brand's potential for global growth, driven by the <strong>universal appeal of chicken wings</strong> and the simplicity of the operational model</li>
    </ul>
  </li>
  <li>The relationship between franchisees and Wingstop's corporate team has been mixed, with some challenges noted due to <strong>high turnover among corporate staff</strong> - despite these issues, the franchisee praises the <strong>CEO</strong> for his leadership and consistency, which has provided a stable vision for the company; however, they also note that frequent changes in corporate personnel can lead to inconsistencies in communication and support, affecting franchise operations
    <ul>
      <li>The franchisee's involvement with the <strong>New Jersey and New York Wingstop co-op</strong>, a group formed to pool resources for regional advertising and other initiatives, highlights a collaborative approach among franchisees - this co-op allows for more <strong>substantial advertising efforts</strong> than individual stores could afford, leveraging collective funds for broader impact; the co-op's activities are primarily communicated through group chats and occasional meetings, reflecting a flexible and adaptive approach to regional marketing efforts</li>
    </ul>
  </li>
  <li>Looking ahead, the franchisee expresses <strong>cautious optimism</strong> about Wingstop's growth prospects; while the brand has been expanding rapidly, particularly in regions like New Jersey and New York, there is a recognition of the need for careful <strong>market saturation management</strong> to avoid cannibalizing existing stores - the franchisee believes there is still <strong>room for growth</strong>, estimating that the market could support additional stores without negatively impacting current locations</li>
  <li>The franchisee also reflects on the broader fast-casual market, noting that Wingstop's <strong>focused menu and high-quality product</strong> differentiate it from competitors; the emphasis on <strong>fresh, cooked-to-order chicken wings</strong> sets Wingstop apart from other chains that use frozen products - this focus on quality, combined with strategic pricing and a strong digital presence, positions Wingstop well in a competitive market</li>
</ul>

          `,
        ],
        image: "/spotlight/wingstop_4.jpg",
      },
      {
        id: 5,
        title: "Spotlight #5 - Wingstop Franchise Owner - August 2024",
        description:
          "Dives into the puts and takes of site selection when franchisees look to expand and opines on the impact of the launch of chicken sandwich, posing a slight departure from the traditional wings-centricity of the concept but noting its meaningful success as a value-option in the current macro",
        points: [
          `
          <ul>
  <li>The franchise owner transitioned from a career in hospitality, starting in <strong>2004 with Holiday Inn Express</strong>, before moving to a larger resort hotel where they gained experience with restaurant operations
    <ul>
      <li>In <strong>2014</strong>, the opportunity to become a <strong>brand partner for Wingstop</strong> arose, driven by <strong>attractive sales per square foot</strong> and the simplicity of operations; the franchise model appealed due to its <strong>low complexity</strong>, ease of training staff, and ability to manage with a <strong>lean crew</strong>, particularly during <strong>peak times like the NFL season</strong></li>
      <li>The franchise owner opened their <strong>first Wingstop location in 2016</strong>, followed by a second six months later in 2017; initial challenges included establishing brand recognition in the <strong>Northeast</strong>, where Wingstop was <strong>relatively new</strong></li>
    </ul>
  </li>
  <li>The <strong>first location broke even on day one</strong>, indicating strong market demand, while the second location faced slower growth initially due to its <strong>less ideal location in a strip center</strong> away from high-population areas; however, the opening of a nearby <strong>Taco Bell</strong> in 2019 boosted traffic and sales
    <ul>
      <li>Delivery services, particularly through <strong>DoorDash</strong>, became a crucial component in driving sales, especially during the COVID-19 pandemic, helping the store reach <strong>$1 million in annual sales</strong></li>
      <li>The franchise owner is currently developing a <strong>third location</strong> in a <strong>110-year-old building</strong>, facing significant delays and cost overruns due to regulatory hurdles and the high cost of construction, with final costs expected around <strong>$600,000</strong>; plans for a fourth and fifth location are underway, with a timeline of opening within the next two years</li>
    </ul>
  </li>
  <li>Rising interest rates and increased construction costs are key concerns, with build-out expenses increasing from around <strong>$400,000-$450,000</strong> to over <strong>$600,000</strong> for new locations; these factors impact the financial planning and debt servicing for new stores
    <ul>
      <li>The owner adopts a conservative financial approach, planning for a <strong>five- to six-year payback period</strong> for new stores, balancing debt service with the need for growth; the financial model is stressed by the recent opening of nearby Wingstop locations, impacting local market saturation</li>
      <li>Despite these challenges, the owner reports healthy sales, with one store expecting a <strong>20% year-over-year growth</strong>; four-wall margins initially expected around <strong>22%</strong> have realistically been closer to <strong>15%</strong>, influenced by higher labor costs and generous employee compensation to maintain low turnover</li>
    </ul>
  </li>
  <li>The recent launch of the <strong>chicken sandwich</strong> has been a significant success, with strong initial sales and positive reception due to its value proposition; the sandwich, priced competitively, offers a substantial meal that rivals other fast-food offerings
    <ul>
      <li>The franchise owner highlights the sandwich's role in attracting new customers and boosting <strong>lunchtime sales</strong>, traditionally a slower period for Wingstop; this addition complements the core menu items, helping to offset the <strong>high cost of wings and tenders</strong></li>
      <li>The owner expresses confidence in Wingstop's ability to maintain competitive pricing, even as food costs fluctuate, noting that <strong>prices are likely to remain stable</strong> even as supply costs decrease; this strategy aims to protect margins and manage consumer perceptions of value</li>
    </ul>
  </li>
  <li>The franchise owner acknowledges the potential challenges posed by an upcoming recession, noting that Wingstop's menu and pricing provide some insulation against economic downturns; the <strong>Chicken Sandwich</strong> is seen as a particularly valuable product during tougher economic times due to its affordability
    <ul>
      <li>The owner is cautious about rapid expansion, citing the <strong>high cost of premium locations</strong> and the uncertainty of the economic environment; however, there is optimism about continued growth in the Northeast and other less saturated markets</li>
      <li>The interview also touches on the broader Wingstop network, with the franchise owner noting that corporate has stopped selling new franchises in established markets, likely to manage market saturation and protect existing franchisees</li>
    </ul>
  </li>
  <li>The owner expresses satisfaction with the support received from Wingstop's corporate team, noting that compared to other franchise systems like Dunkin' Donuts or Pizza Hut, Wingstop offers <strong>strong backing and a clear operational focus</strong>
    <ul>
      <li>There is a general sense of optimism among Wingstop franchisees, with many planning further expansion despite current economic uncertainties; however, there is also a recognition of the challenges posed by rising costs and the need for careful site selection</li>
      <li>The interviewee's perspective on the broader quick-service restaurant (QSR) industry includes observations on other franchises like <strong>Jersey Mike's</strong> and <strong>Tropical Smoothie Cafe</strong>, highlighting differences in operational complexity and market appeal</li>
    </ul>
  </li>
  <li>Delivery has become a significant part of the business, accounting for approximately <strong>35% of sales</strong>; the addition of Uber Eats alongside DoorDash has expanded delivery options, though the impact is considered incremental
    <ul>
      <li>The owner notes the potential for <strong>further partnerships</strong>, such as with Grubhub, though there is skepticism about its impact compared to the established players like <strong>DoorDash and Uber Eats</strong></li>
    </ul>
  </li>
  <li>The franchise owner observes <strong>seasonal fluctuations in sales</strong>, with strong performances around sports events and challenges during holiday periods when customers are likely to travel; recent advertising campaigns, particularly during NFL games, have been positively received and are expected to drive further sales growth
    <ul>
      <li>The Chicken Sandwich's introduction is expected to contribute significantly to sales, potentially <strong>adding 5-6% to the top line</strong>, with initial results indicating strong customer interest and incremental growth</li>
    </ul>
  </li>
</ul>

          `,
        ],
        image: "/spotlight/wingstop_5.jpg",
      },
      {
        id: 6,
        title: "Spotlight #6 - Wingstop Franchise Owner - September 2024",
        description:
          "Interviewee discusses the strong interest of institutional capital in buying out multi-unit franchisee groups and expresses optimism for how vertical integration can lead to supply chain stability and profit margin expansion",
        points: [
          `
          <ul>
  <li>The franchise owner operates <strong>5 stores within a defined territory</strong>, with no current development agreements allowing for further expansion; the market saturation in the area has limited opportunities for opening new stores organically, leading to a situation where the only expansion possibility is through <strong>acquiring existing stores</strong> from other franchisees</li>
  <li>There has been <strong>significant interest from potential buyers</strong>, with five different groups expressing interest in purchasing the owner's stores, three of which are willing to pay cash
    <ul>
      <li>The valuations being offered are around <strong>5x EBITDA</strong>, reflecting strong demand and the perceived value of the brand, with many offers coming from existing franchisees looking to expand their holdings</li>
    </ul>
  </li>
  <li>Wing prices have seen significant fluctuations, impacting profitability; last year, prices were as high as <strong>$3.22 per pound</strong>, but have recently dropped to around <strong>$1.63 per pound</strong>, which has alleviated some of the cost pressures
    <ul>
      <li>This drop in wing prices has <strong>revitalized franchisee interest</strong> in expanding their store count, with the potential for <strong>10% growth in store numbers</strong> within the area, translating to about <strong>5 new stores</strong></li>
      <li>Franchisees are <strong>cautiously optimistic</strong>, understanding that while wing prices have decreased, there is always the possibility of future increases</li>
    </ul>
  </li>
  <li>The cost of opening a new Wingstop has risen from about <strong>$350,000 15 years ago</strong> to around <strong>$500,000-$550,000 today</strong>; the increase is largely attributed to <strong>higher labor costs</strong> and general inflation in material costs
    <ul>
      <li>Despite the increase in capital expenditure, new stores are expected to generate robust average unit volumes (AUVs), typically between <strong>$1.3 million and $1.4 million</strong> in the first year</li>
    </ul>
  </li>
  <li>Approximately <strong>70% of the franchise's sales are from delivery</strong>, underscoring the critical role of delivery platforms in the business model; this high percentage of delivery sales reflects consumer behavior shifts, particularly during and post-pandemic
    <ul>
      <li>Of the 70% delivery share, around <strong>60% comes from Wingstop's own app or website</strong>, while <strong>40% is facilitated through third-party platforms</strong> like DoorDash</li>
    </ul>
  </li>
  <li>The franchise currently pays DoorDash a fee of <strong>14.5% on gross sales</strong>, which includes credit card processing fees; this is a reduction from a previous 20% fee, achieved through corporate negotiations
    <ul>
      <li>DoorDash marks up the price of items by approximately <strong>15%</strong>, meaning that while a product might cost <strong>$11.99</strong> on Wingstop's platform, it would be priced at <strong>$13.79</strong> on DoorDash; despite the markup, customers continue to use DoorDash due to its convenience and the platform's broad visibility</li>
    </ul>
  </li>
  <li>The franchise owner reported varied same-store sales performance in the first half of the year, with a notable spike in June attributed to the <strong>NBA Finals</strong> and significant advertising during the games
    <ul>
      <li>Specific monthly performance: January up <strong>12.88%</strong>, February down <strong>1%</strong>, March down <strong>6.5%</strong>, April down <strong>2.99%</strong>, May up <strong>5.84%</strong>, and June estimated up <strong>7.15%</strong></li>
    </ul>
  </li>
  <li>The national TV advertising campaign, particularly during major sporting events, has been effective in boosting brand visibility and driving sales; Wingstop's strategic placement of ads during high-visibility events like the <strong>NBA Finals</strong> has reinforced its brand image and appeal</li>
  <li>Wingstop uses <strong>Olo</strong> for its online ordering system, with a fixed fee per location; this system has been integral in managing digital orders and integrating with delivery platforms
    <ul>
      <li>The franchise owner notes a shift where the cost of Olo is now covered by the franchisees rather than the marketing fund, though the fee has been slightly reduced</li>
    </ul>
  </li>
  <li>The discussion touched on the introduction of new products like the <strong>chicken sandwich</strong>, which, although not yet available at the interviewee's locations, has been positively received in taste tests
    <ul>
      <li>The owner also noted the end of the <strong>Thighstop</strong> virtual brand, which did not perform as expected, accounting for <strong>less than 2% of sales</strong></li>
      <li>Wingstop's consideration of <strong>vertical integration</strong>, including owning chicken farms, is seen as a positive strategic move to stabilize supply and potentially reduce costs; this initiative could help mitigate supply chain disruptions and improve profit margins</li>
    </ul>
  </li>
  <li>The franchise owner remains <strong>optimistic about the future</strong>, with ongoing interest from potential buyers and strong sales performance; the potential for further <strong>growth through acquisitions</strong> remains, particularly if corporate initiatives like vertical integration are successful
    <ul>
      <li>There is also acknowledgment of the challenges ahead, including potential increases in wing prices and labor costs, but the overall outlook remains positive given the brand's strong market position and the ongoing demand for its products</li>
    </ul>
  </li>
</ul>


          `,
        ],
        image: "/spotlight/wingstop_6.webp",
      },
    ],
  },
  {
    slug: "Domino's",
    pageData: {
      pic: "/logos/Domino's Pizza.jpg",
      title: "Domino's",
      initialInvestment: "$160k-$683k",
      franchiseeFee: "$10k",
      royaltyRate: "5.5%",
      description:
        "Domino's Pizza Inc, founded in 1960, has evolved into a global leader in the pizza industry with over 18k locations in more than 90 countries. Initially growing significantly in the late 1980s and 1990s, the company overcame financial struggles through Bain Capital's 1999 acquisition and a successful public offering in 2004. Known for its technological innovations, such as multiple ordering methods and real-time GPS tracking, Domino's relies heavily on its franchise model, with 99% of stores franchised, promoting rapid expansion. Despite recent challenges like labor shortages and competition from gig economy jobs, Domino's has adapted by partnering with third-party delivery services while maintaining a focus on delivery quality. Under strategic leadership, the company continues to thrive, leveraging strong brand recognition and customer loyalty for sustained growth.",
    },
    keyTrends: [
      {
        leftDescription:
          "Domino's franchisees enjoy strong profitability with an average <strong>EBITDA around $150k</strong> and a return on investment (ROI) within <strong>3 to 5 years</strong>",
        rightDescription:
          "Faces competitive pressure from <strong>third-party delivery services like Uber Eats and DoorDash</strong>, leading to a strategic partnership to leverage their customer base while maintaining delivery control",
      },
      {
        leftDescription:
          "Invests heavily in <strong>technology</strong>, including <strong>advanced ordering systems</strong> and <strong>GPS tracking</strong>, to enhance customer convenience and operational efficiency",
        rightDescription:
          "The company struggles with maintaining its <strong>30–40-minute delivery promise</strong> due to labor shortages as franchisees are <strong>unable to exercise wage flexibility</strong> to attract more drivers (must pay within a certain range decided by corporate)",
      },
      {
        leftDescription:
          "<strong>Broad-based appeal of pizza</strong> coupled with the strong consumer trust in Domino’s due to <strong>tight consistency</strong> in product quality and delivery allows for macro resiliency",
        rightDescription:
          "Domino's faces <strong>margin pressures</strong> with increased delivery driver costs post-COVID, exacerbated by competition from higher-paying gig economy jobs",
      },
    ],
    cardData: [
      {
        id: 1,
        title:
          "Spotlight #1 – Former Franchisee Owner at Dominos – August 2023",
        description:
          "Focus on initial delivery challenges and subsequent strategic partnerships while running through store unit economics and valuation",
        points: [
          `
          <ul>
    <li>The interviewee is a <b>former Domino's franchisee owner</b> with over <b>20 years of experience</b>, left Domino's in <b>February 2016</b>
        <ul>
            <li>Managed <b>13 franchise stores</b>, including <b>9 located in University of Florida stadiums</b>, overseeing all aspects of operations, marketing, and sales and sales of around <b>$12M</b></li>
        </ul>
    </li>
    <li>The expert purchased first store in <b>1995</b>, with <b>$250k investment</b> expanding to <b>13 stores</b> and leading them to become the <b>busiest Domino's franchise globally</b> for <b>5 years</b> and sold the stores at <b>6x EBITDA</b>
        <ul>
            <li>The interviewee held significant roles in Domino's, including serving on <b>advisory boards</b> and contributing to the selection of the <b>original POS system</b></li>
            <li>The expert received multiple accolades, including <b>Franchise of the Year 6 times</b> and <b>National Manager of the Year three times</b></li>
        </ul>
    </li>
    <li>Dominos faced labour shortages due to significant increase in costs of delivery drivers after COVID, the delivery companies are paying <b>$30/hour</b>, whereas Dominos pay <b>$15/hour</b> which is much less than the market - this is the reason they face delivery driver shortages
        <ul>
            <li>The management of Dominos direct the franchisee to pay a range to the delivery drivers, the franchisee themselves cannot decide to pay outside of that</li>
            <li>Domino's faces challenges in competing with companies like <b>Amazon</b> for labour, as Amazon pays significantly higher wages</li>
            <li>The previous <b>CEO</b> did not raise wages to compete with higher-paying jobs, but the new leadership under <b>Dave Brandon</b> is now making changes to remain competitive in the market</li>
        </ul>
    </li>
    <li>The labour market, particularly after COVID-19, has posed challenges for delivery staffing, leading to partnerships with third-party delivery services like <b>Uber Eats, Amazon and DoorDash</b></li>
    <li>Dominos is the market leader, and its major strength is its fastest delivery in <b>30-40 minutes</b> if you order directly from the company
        <ul>
            <li>The competitor companies take at least <b>50-60 minutes</b> to deliver a pizza; <b>Papa John’s</b> average delivery time is <b>1 hour or greater</b></li>
        </ul>
    </li>
    <li>Has <b>advanced ordering systems</b> enabling multiple ways to order - including through social media; <b>GPS technology</b> allows precise delivery locations, enhancing customer convenience through pinpoint delivery</li>
    <li>Buying a Domino franchise is very easy anyone can buy there is no limitation to apply for franchisee rights – corporate <b>requires every growing franchisee to split into two stores</b> to increase penetration
        <ul>
            <li>Franchisees have opportunities to purchase existing stores or build new ones - the cost of building a new store is approximately <b>$400k</b>, while purchasing an existing store range between <b>$200k and $400k</b></li>
            <li>Profitability remains strong with average EBITDA around <b>16%</b></li>
        </ul>
    </li>
    <li>Many Domino's franchisees start as delivery drivers and work their way up, which contributes to a deep understanding of the business and operational success</li>
    <li><b>99%</b> of Domino's stores are franchised, allowing for rapid expansion and local market penetration</li>
    <li>The corporation earns from <b>commissaries</b> and franchise royalties, making it less reliant on direct store revenue</li>
    <li>Dominos faces dual competition first with Pizza chains – for example <b>mom-and-pop stores, Papa Johns</b> and other pizza chains, and the other competition is third-party delivery services - like <b>Uber Eats and DoorDash</b>
        <ul>
            <li>Companies like <b>DoorDash and Uber Eats</b> have changed the delivery landscape, often charging higher fees than Domino's; despite this, the demand for food delivery remains strong</li>
        </ul>
    </li>
    <li>Domino's effectively targets different generations through <b>strategic advertising</b>, including a strong presence on platforms like <b>TikTok</b></li>
    <li>Dominos has struggled with delivery times due to a lack of drivers, but it remains competitive in delivery speed Domino's still leads in delivery speed compared to competitors but has faced challenges in meeting the <b>30–40-minute delivery expectation</b> due to driver shortages
        <ul>
            <li>Due to lack to delivery drivers now the company has partnered with <b>Uber Eats</b> to improve delivery operations</li>
            <li>Customers prioritize ease of ordering, quality, consistency, and fast delivery; <b>online ordering</b> is particularly important for convenience</li>
            <li>Dominos has invested heavily in technology, making it easy for customers to <b>order online</b> through various platforms, including text, social media, and even through car interfaces</li>
        </ul>
    </li>
    <li>Pizza is a <b>universally loved food</b> across all demographics and income levels in the US and international markets
        <ul>
            <li>Pizza consumption spans a wide demographic, with no specific category of pizza eaters - preferences vary by <b>convenience, value, taste</b>, and support for local versus corporate brands</li>
            <li>Domino has included pizza types in the menu that supports food preferences like <b>vegan, vegetarian and gluten free options</b></li>
            <li>Nationally <b>consistent pricing</b> builds consumer trust and simplifies the ordering process</li>
        </ul>
    </li>
    <li>Acquisition by <b>Bain Capital (1999)</b> marked a significant financial turnaround for Domino's, leading to improved management and profitability
        <ul>
            <li><b>IPO in 2003</b> provided Domino's with the capital to expand and innovate, contributing to its substantial growth</li>
        </ul>
    </li>
    <li>Domino's corporate supports franchisees with marketing and operational guidance to ensure profitability; with a <b>national advertising budget of around $240 million</b>, Dominos maintains a strong presence in the market and targets every generation</li>
</ul>

          `,
        ],
        image: "/spotlight/dom_4.png",
      },
      {
        id: 2,
        title:
          "Spotlight # 2 – Former Director of Franchise Development at Domino’s Pizza Inc– September 2023",
        description:
          "Overview of site real estate dynamics, performance across various international markets, and longer-term growth trends for the concept",
        points: [
          `
          <ul>
  <li>The interviewee is a <strong>former Director of Franchise Development</strong> at Domino's Pizza Inc, bringing extensive experience in overseeing the expansion and strategic direction of the franchise network</li>
  <li>Franchisees are paying approximately <strong>50% more for Tier B locations</strong> compared to lower-tier options
    <ul>
      <li>5 years ago, franchisees were more inclined to invest in higher-tier properties (Tier D), but now there is a <strong>50-50 split</strong> between Tier B and Tier C locations</li>
      <li>About 50% of new store locations are in Tier B, with the rest in Tier C or lower</li>
    </ul>
  </li>
  <li>The interviewee estimates that Dominos could potentially reach <strong>8,000 to 8,500 stores</strong> in the US over the long term
    <ul>
      <li>High building costs and decreased EBITDA have made franchisees <strong>hesitant to expand</strong>, and this reluctance may persist even if economic conditions improve</li>
      <li>The company has been lenient with franchisee development commitments, allowing some <strong>flexibility in meeting obligations</strong>; under new leadership, there may be stricter enforcement of these commitments, which could create challenges for franchisees accustomed to a more relaxed approach</li>
    </ul>
  </li>
  <li>The <strong>international team</strong> is well-run, and there is optimism about continued growth in markets outside the US
    <ul>
      <li>Problems with master franchisees, particularly in Australia and Brazil, have impacted store performance. In Brazil, challenges with site selection and market adoption have been significant</li>
      <li><strong>Australia:</strong> Facing operational issues, with recent store closures. The situation may reflect broader macroeconomic challenges rather than market saturation</li>
      <li><strong>Mexico:</strong> The market is performing well, with a strong franchisee (Alsea) and healthy growth</li>
      <li><strong>Brazil:</strong> Struggles due to site selection and marketing issues, with limited reasons for optimism in the near term</li>
    </ul>
  </li>
  <li>Despite current challenges and slower growth, the interviewee remains confident in Domino’s <strong>long-term potential</strong> and would still invest in the company, albeit acknowledging that past growth rates may not be achievable in the near term</li>
  <li>Dominos has signed an <strong>exclusive agreement with Uber Eats</strong> but will handle delivery in-house - this approach is intended to leverage Uber Eats for customer acquisition without compromising delivery control
    <ul>
      <li>Franchisees are generally supportive of this move, especially in competitive labor markets, but the impact on incremental profits remains uncertain</li>
      <li>The strategy might not significantly boost profits due to Domino’s existing strong brand recognition and customer loyalty</li>
    </ul>
  </li>
</ul>

          `,
        ],
        image: "/spotlight/dom_2.png",
      },
      {
        id: 3,
        title:
          "Spotlight # 3 – Former Director of Franchise Development at Domino’s Pizza Inc– August 23, 2023",
        description:
          "Explores the interrelated dynamics of agreements with third-party aggregators, labor shortages, and the competitive advantage of Domino’s delivery and consumer trust",
        points: [
          `
          <ul>
  <li>The interviewee, a <strong>former Director of Franchise Development</strong> at Domino's Pizza Inc, offers a wealth of insights into the operational, financial, and strategic aspects of the company
    <ul>
      <li>His role included working closely with franchisees to ensure their success, identifying <strong>new market opportunities</strong>, and implementing strategies to enhance the profitability and operational efficiency of the stores</li>
    </ul>
  </li>
  <li>Quick Service Restaurant (QSR) industry, including Domino's, faces a <strong>labor shortage</strong> due to the less attractive nature of the job, which often involves night and weekend shifts and requires drivers to use their own vehicles
    <ul>
      <li>The <strong>flexibility</strong> of gig economy jobs like Uber Eats and DoorDash makes them more appealing compared to traditional delivery roles</li>
    </ul>
  </li>
  <li>Domino's could improve <strong>employee retention</strong> by offering career advancement opportunities and additional benefits such as healthcare, tuition reimbursement, and retirement savings
    <ul>
      <li>Emphasizing <strong>career growth</strong> from delivery driver to store manager or franchise owner can enhance the value proposition for employees</li>
    </ul>
  </li>
  <li><strong>Carry-out orders</strong>, especially those placed digitally and prepaid, are more profitable for Domino's compared to delivery orders due to reduced labor and operational costs
    <ul>
      <li>There are distinct customer bases for delivery and carry-out, and economic conditions might shift some delivery customers towards carry-out to save on delivery fees</li>
    </ul>
  </li>
  <li>Opening a Domino's store typically requires an <strong>investment</strong> ranging from <strong>$400k to $1 million</strong>, depending on the location and type of build
    <ul>
      <li>Franchisees generally seek a return on investment (ROI) within three years, with an average store-level EBITDA of around <strong>$150k</strong> – this is considered highly lucrative, especially when compared to other pizza chains like Papa John’s, which might generate only half of that amount</li>
      <li>The company’s expansion plans are more realistic over a 5-6 year period, aiming to add <strong>250 new units</strong> annually</li>
    </ul>
  </li>
  <li>Despite the rise of delivery platforms, competitors like <strong>Little Caesars</strong> remain strong in the carry-out segment
    <ul>
      <li>The proliferation of delivery options has increased competition, but Domino's <strong>competitive advantage</strong> lies in its ability to deliver a consistently high-quality product</li>
      <li>While third-party services offer a wide range of food options and heavily subsidized promotions, Domino's relies on the quality and consistency of their delivery experience to retain customers; the interviewee expressed confidence that Domino's value proposition would remain strong even if third-party services stopped offering subsidies</li>
      <li>Domino's maintains a significant competitive edge by controlling its own delivery system, which allows them to ensure a more consistent, hotter, and fresher product compared to competitors who rely on third-party delivery services like Uber Eats and DoorDash</li>
    </ul>
  </li>
  <li>Domino's is exploring the use of <strong>company-owned vehicles</strong>, including electric fleets, to address labor shortages - this approach can reduce insurance costs and attract drivers who lack their own vehicles
    <ul>
      <li>Domino's places a strong emphasis on encouraging customers to place orders digitally, whether through their app or online</li>
      <li>Digital orders that are prepaid and picked up carry-out are the <strong>most profitable</strong>, as they streamline the process and reduce labor costs</li>
      <li>The Domino's app and <strong>loyalty programs</strong> are critical components of their marketing strategy - the app not only facilitates easy ordering but also allows customers to earn rewards, enhancing customer retention and encouraging repeat business</li>
    </ul>
  </li>
</ul>

          `,
        ],
        image: "/spotlight/dom_3.png",
      },
      {
        id: 4,
        title:
          "Spotlight # 4 – Former Director of Franchise Development at Domino’s Pizza Inc– July 28, 2023",
        description:
          "Discussion on localization and menu diversification to appeal to a broader base of evolving demographics while noting Domino’s share gains and technological leadership",
        points: [
          `
          <ul>
  <li>The interviewee, a former <strong>Director of Franchise Development</strong> at Domino’s Pizza, brings a wealth of experience and insider knowledge to the discussion about the company's franchise model, growth strategies, and competitive positioning.</li>
  <li>Domino's is a <strong>well-known and trusted brand globally</strong> - this brand recognition helps drive initial interest and adoption in new markets.</li>
  <li>Domino's has been successful in adapting its menu to cater to <strong>local tastes and preferences</strong> - this includes offering region-specific toppings and sides, which helps the brand resonate with local consumers.
    <ul>
      <li>The expert highlights the need for Domino's to embrace more innovation, including <strong>vegan alternatives</strong> and new products.</li>
      <li>The <strong>Domino's app</strong> is a crucial tool for attracting and retaining customers, though younger generations may be less loyal.</li>
    </ul>
  </li>
  <li>The franchise remains highly profitable, with an <strong>average EBITDA of $134k</strong> and a return on investment within <strong>~5 years</strong>, making it an appealing option in the quick-service restaurant (QSR) sector.
    <ul>
      <li>The carryout segment has grown significantly, now accounting for over <strong>40% of sales</strong>, thanks to strategic real estate placements and national advertising - however, future growth in this area may be slower.</li>
    </ul>
  </li>
  <li>The interviewee also discussed the impact of <strong>third-party aggregators</strong>, which offer more delivery options and could affect Domino's market share.
    <ul>
      <li>They also mentioned the challenge of <strong>cannibalization</strong>, where new Domino's stores might take sales from existing ones, and the rise of ghost kitchens as a potential growth avenue.</li>
      <li>About <strong>60% of franchisees</strong> prefer third-party aggregators to handle deliveries, while <strong>40% support Domino's traditional delivery model.</strong></li>
      <li>The expert suggests that Domino's could negotiate a fee of <strong>15-20%</strong> with Uber Eats, which is lower than the standard <strong>25-35%</strong> for smaller chains.</li>
    </ul>
  </li>
  <li>Despite Domino's <strong>historical leadership in technology</strong>, continuous innovation is necessary to maintain its edge.
    <ul>
      <li>Environmental initiatives, such as <strong>electric delivery vehicles</strong>, are positive but face practical challenges for widespread adoption by franchisees.</li>
    </ul>
  </li>
  <li>Finally, the interviewee suggested that Domino's might need to adapt its franchise model to allow for faster growth, possibly by integrating more <strong>large area developers</strong> or modifying sponsorship requirements for new franchisees.
    <ul>
      <li>The expert believes that big chains, except <strong>Chick-fil-A</strong>, may lose market share to smaller competitors and aggregators.</li>
      <li>Domino's has successfully <strong>taken market share</strong> from smaller, independent pizza shops, particularly in localized markets - this opportunity remains significant, especially in regions with a high density of mom-and-pop pizza stores.</li>
    </ul>
  </li>
</ul>


          `,
        ],
        image: "/spotlight/dom_1.png",
      },
      {
        id: 5,
        title:
          "Spotlight # 5– Former Director of Franchise Development at Domino’s Pizza Inc– February 13, 2023",
        description:
          "Highlighted the strengths and challenges faced by Domino's, particularly in the context of the COVID-19 pandemic, and offered insights into the company's succession planning, international expansion, and innovation efforts",
        points: [
          `
          <ul>
    <li>The interviewee is a former <strong>Director of Franchise Development</strong> at Domino's Pizza Inc, who left the company in <strong>April 2021</strong> - during his tenure, he oversaw a team responsible for the growth of <strong>318 franchise groups</strong> and <strong>1,900 stores across 18 states</strong></li>
    <li>Responsible for a team overseeing the growth of <strong>318 franchises</strong> and <strong>1,900 stores across 18 states</strong> Accountable for franchising strategies, sales budget analysis, and market growth
        <ul>
            <li>The success of Domino's Pizza is largely attributed to their passionate and experienced franchisees, many of whom began their careers as <strong>drivers</strong></li>
            <li>The franchise model requires potential franchisees to <strong>manage a store for at least a year</strong> and have a <strong>sponsor</strong> from within the franchisee network, which results in higher operational efficiencies</li>
            <li>Challenges during the pandemic included <strong>bringing back drivers</strong> and a decrease in sales</li>
            <li>The expert emphasized the potential need for <strong>new management perspectives</strong> to address issues with third-party aggregators and improve service times</li>
        </ul>
    </li>
    <li>Passionate franchisees lead to <strong>better-trained staff</strong>, resulting in higher operating efficiencies and <strong>lower attrition rates</strong> compared to industry standards
        <ul>
            <li>Domino’s has a strong talent pool and <strong>succession planning</strong> but faces issues with <strong>retaining talent</strong> and balancing experience with youthful leadership - this sometimes creates tension with franchisees who may find younger leaders less credible</li>
        </ul>
    </li>
    <li>Innovations such as <strong>improved pizza boxes</strong>, <strong>delivery apps</strong>, and <strong>GPS tracking systems</strong> contribute to operational efficiencies
        <ul>
            <li>There is still substantial <strong>growth potential</strong> in the pizza industry in the US, but Domino’s needs to address the shift towards <strong>healthier options</strong> and improve their approach to aggregators</li>
        </ul>
    </li>
    <li>All major innovations come from the Domino's <strong>Innovation Garage</strong>, ensuring uniformity and standardization across stores - while innovation is centralized, franchisees play a critical role in <strong>local market adaptations</strong> and operational improvements</li>
    <li>A major challenge was the inability to bring back drivers post-pandemic, affecting delivery times and sales
        <ul>
            <li>The company needs to re-evaluate its stance on <strong>third-party aggregators</strong> and explore new delivery methods, including <strong>automated vehicles</strong>, despite the high costs involved</li>
        </ul>
    </li>
    <li>The rise of <strong>third-party delivery services</strong> poses a <strong>significant threat</strong> - Domino's traditionally resisted these aggregators, but the pandemic highlighted the need for potential strategic shifts
        <ul>
            <li>The company is slow to adapt, and their focus on <strong>in-house delivery</strong> is seen as a potential weakness</li>
        </ul>
    </li>
    <li>Domino’s <strong>international business</strong> is generally more successful than domestic operations, with significant growth in countries like <strong>India</strong>; success varies by market, with some regions outperforming others due to factors such as marketing effectiveness and local execution
        <ul>
            <li>During the interview, it was noted that franchisees in Domino’s had an average <strong>EBITDA of > $136k per year</strong> - this figure reflects the strong profitability of the franchise model</li>
        </ul>
    </li>
    <li>Franchise agreements vary by country and are influenced by factors like <strong>market maturity</strong> and <strong>local conditions</strong> - agreements often include royalty abatements and development fees to support growth</li>
    <li>Despite being stuck in traditional models, Domino’s is <strong>exploring innovations</strong> like <strong>driverless vehicles</strong> and alternative delivery methods - however, competitors have already adapted to aggregator challenges more effectively
        <ul>
            <li><strong>Carryout</strong> already constitutes a significant portion of Domino’s business and is a strong aspect of their model - further increasing carryout is seen as a way to mitigate delivery challenges, but its growth potential may be limited</li>
        </ul>
    </li>
    <li>Companies like <strong>Chick-fil-A</strong> and emerging brands like <strong>Slim Chickens</strong> and <strong>Chicken Salad Chick</strong> are noted as strong players in the QSR space, with potential for continued success both domestically and internationally</li>
</ul>


          `,
        ],
        image: "/spotlight/dom_5.png",
      },
    ],
  },
];

export const getRecordBySlug = (slug) => {
  return wingStopData.find(
    (record) => record.slug.toLowerCase() === slug.toLowerCase()
  );
};

import React, { useState, useEffect } from "react";
import "./App.css";
import CssBaseline from "@mui/material/CssBaseline";
import FranchiseTable from "./FranchiseTable";
import FeedbackForm from "./FeedbackForm";
import FullVersionForm from "./FullVersionForm";
import BookFranchiseePage from "./BookFranchiseePage";
import ReviewPage from "./ReviewPage";
import { FranchiseProvider } from "./context/FranchiseContext";
import DashboardPage from "./DashboardPage";
import { useAuth } from "./auth/Auth";
import Joyride, { STATUS } from "react-joyride";
import CustomAppBar from "./components/CustomAppBar";
import { useMediaQuery, useTheme } from "@mui/material";
import Home from "./Home";
import {
  useLocation,
  useNavigate,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import SuccessPage from "./components/Success";
import Blog from "./components/BlogComponent/Blog";
import BlogPage from "./components/BlogComponent/BlogPage";
import BlogDefault from "./components/BlogComponent/SingleBlog";
import Insight from "./components/Insight";
import InsightList from "./components/InsightList";

const App = () => {
  const [selectedFranchise, setSelectedFranchise] = useState(null);
  const [cardPosition, setCardPosition] = useState({ top: 0, left: 0 });
  const [selectedOption, setSelectedOption] = useState("");
  const [runTour, setRunTour] = useState(false); // Controls whether the tour is running
  const location = useLocation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [steps, setSteps] = useState([
    {
      target: "body",
      content:
        "Welcome to FranchiseNow! Let’s take a quick tour of the platform.",
      placement: "center",
      disableBeacon: true,
    },
    {
      target: ".wholeTable",
      content: "View critical data on +1,400 franchises entirely for free",
      disableBeacon: true,
    },
    {
      target: ".auto-apply-column",
      content:
        'Use the "Auto Apply" button to automatically apply to selected franchises, saving you time and effort. It’s a quick way to express your interest in multiple opportunities at once!',
      disableBeacon: true,
    },
    {
      target: ".FDD",
      content:
        "Download the full Franchise Disclosure Document for any franchise all for free",
      disableBeacon: true,
    },
    {
      target: ".all-franchises-tab",
      content:
        "Welcome to the 'All Franchises' section! Here, you can explore our extensive list of over +1,400 franchise opportunities",
    },
    {
      target: ".book-franchisee-tab",
      content:
        "Schedule calls with real franchisees to get the inside scoop and do real diligence",
    },
    {
      target: ".reviews-tab",
      content:
        "In the 'Franchisee Reviews' section, get firsthand insights from current and former franchisees",
    },
    {
      target: ".feedback-tab",
      content:
        "Got suggestions? The 'Site Suggestions' area is where you can share your thoughts and feedback",
    },
    {
      target: ".full-access-tab",
      content:
        "Interested in unlocking all features? The 'Full Access' section provides information on how to get complete access",
    },
  ]);

  const theme = useTheme();

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  // Handle the callback for tour events
  const handleJoyrideCallback = (data) => {
    const { status } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false); // End the tour
    }
  };

  // Example method to start the tour, could be called on first login or via a "Start Tour" button
  const startTour = () => {
    setRunTour(true);
  };

  const handleColumnClick = (franchise, event) => {
    setCardPosition({ top: event.clientY, left: event.clientX });
    setSelectedFranchise(franchise);
  };

  const handleChange = (event, newValue) => {
    setSelectedOption(newValue);
    switch (newValue) {
      case "home":
        navigate("/");
        break;
      case "reviews":
        navigate("/franchisee-reviews");
        break;
      case "feedback":
        navigate("/site-suggestions");
        break;
      case "fullAccess":
        navigate("/full-access");
        break;
      case "bookFranchisee":
        navigate("/book-franchisee");
        break;
      default:
        navigate("/");
    }
  };
  const navigateToDashboard = () => {
    setSelectedOption("dashboard");
    navigate("dashboard");
  };
  const navigateHome = () => {
    setSelectedOption("");
    navigate("/");
  };
  const navigateToExpertSignUp = () => {
    setSelectedOption("");
    window.location.href =
      "https://docs.google.com/forms/d/e/1FAIpQLSfoI7vsHoyVgHp19d_YZ1Qn318ORKTf9ANsMiGM-0vIRVS0XA/viewform";
  };
  useEffect(() => {
    if (location.pathname == "/all-franchises") {
      const tourHasRun = localStorage.getItem("tourHasRun");
      if (!tourHasRun && !isMobile) {
        startTour();
        localStorage.setItem("tourHasRun", "true");
      }
    }
  }, [location]);
  useEffect(() => {
    // Update the selected tab based on the current route
    switch (location.pathname) {
      case "/":
        setSelectedOption("");
        break;
      case "/all-franchises":
        setSelectedOption("franchiseListings");
        break;
      case "/franchisee-reviews":
        setSelectedOption("reviews");
        break;
      case "/site-suggestions":
        setSelectedOption("feedback");
        break;
      case "/full-access":
        setSelectedOption("fullAccess");
        break;
      case "/book-franchisee":
        setSelectedOption("bookFranchisee");
        break;
      default:
        setSelectedOption("");
    }
  }, [location.pathname]);

  return (
    <FranchiseProvider>
      <Joyride
        continuous
        run={runTour}
        steps={steps}
        callback={handleJoyrideCallback}
        showSkipButton
        styles={{
          options: {
            zIndex: 10000, // Keep this to ensure your tooltip is always on top
            primaryColor: "#0098dc", // This changes the primary color, affecting the button color
          },
          buttonNext: {
            backgroundColor: "#0098dc", // Specific color for the "Next" button
          },
          buttonBack: {
            color: "#0098dc", // Text color for the "Back" button if you want it different
          },
          buttonSkip: {
            color: "#0098dc", // Text color for the "Skip" button
          },
        }}
        locale={{
          last: "End", // Change the button text from "last" to "End"
        }}
      />
      <CssBaseline />
      <CustomAppBar
        selectedOption={selectedOption}
        setSelectedOption={setSelectedOption}
        navigateToDashboard={navigateToDashboard}
        navigateHome={navigateHome}
        handleChange={handleChange}
        expertSignUp={navigateToExpertSignUp}
      />
      <div>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route
            path="/all-franchises"
            element={
              <FranchiseTable
                onColumnClick={handleColumnClick}
                className="franchise-table"
              />
            }
          />
          <Route path="/franchisee-reviews" element={<ReviewPage />} />
          <Route path="/site-suggestions" element={<FeedbackForm />} />
          <Route path="/full-access" element={<FullVersionForm />} />
          <Route path="/book-franchisee" element={<BookFranchiseePage />} />
          <Route path="/success" element={<SuccessPage />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/insight" element={<InsightList />} />
          <Route path="/insight/:id" element={<Insight />} />
          <Route path="/blog/:slug" element={<BlogDefault />} />
          <Route
            path="/dashboard"
            element={
              auth.isAuthenticated !== undefined && auth.isAuthenticated ? (
                <DashboardPage />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </FranchiseProvider>
  );
};

export default App;
